import React, { forwardRef, useMemo } from "react";
import styled from "styled-components";
import { splitTextByKeywords, type Options } from "../utils/highlight";

export interface TextWithHighlightProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  text: string;
  keywords: string[];
  options?: Options;
  color?: string;
  bgColor?: string;
}

export const TextWithHighlight = forwardRef<
  HTMLSpanElement,
  TextWithHighlightProps
>((props, ref) => {
  const { text, keywords, options, color, bgColor, ...others } = props;

  const terms = useMemo(() => {
    return splitTextByKeywords(text, keywords, options);
  }, [text, keywords, options]);

  return (
    <span ref={ref} {...others}>
      {terms.map((term, i) => (
        // すべてのテキストを一つのspanにマージ
        <span key={i}>
          {term.hit ? (
            <HighlightText $color={color} $bgColor={bgColor}>
              {term.text}
            </HighlightText>
          ) : (
            term.text
          )}
        </span>
      ))}
    </span>
  );
});

const HighlightText = styled.strong<{ $color?: string; $bgColor?: string }>`
  color: ${({ $color }) => $color || "#343741"};
  background-color: ${({ $bgColor }) => $bgColor || "#ff0"};
`;
