import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createPTAInvoiceImport,
  getPTAInvoiceImportRows,
} from "src/apiClients/ptaInvoiceImport";
import {
  CreatePTAInvoiceImportType,
  GetPTAInvoiceImportRowsResponseType,
} from "@shared/validator/features/ptaInvoiceImport.schema";
import { logger } from "../../../utils/logger";

export function useGetPTAInvoiceImportRows(
  PTAInvoiceProductId: string,
  importId: string
) {
  const query = useQuery<GetPTAInvoiceImportRowsResponseType>({
    queryKey: [
      "api",
      "ptaInvoiceImport",
      "rows",
      PTAInvoiceProductId,
      importId,
    ],
    queryFn: async () => {
      return getPTAInvoiceImportRows({ importId, PTAInvoiceProductId });
    },
    enabled: importId.length > 0,
  });

  return {
    invoiceImportRows: query.data ?? undefined,
    ...query,
  };
}

export function useCreatePTAInvoiceImport() {
  return useMutation({
    mutationFn: (data: CreatePTAInvoiceImportType & { file: File }) =>
      createPTAInvoiceImport(data),
    onSuccess: (response) => {
      console.log("インポート成功:", response);
    },
    onError: (error) => {
      logger.error(error, { message: "インポート失敗" });
    },
  });
}
