import React, { FC } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import styled from "styled-components";

type props = {
  label?: string | FC;
  register?: UseFormRegisterReturn;
  value?: number | string;
};

export const Switch = ({ label, register, value }: props) => {
  return (
    <CheckBoxWrapper>
      <HiddenCheckbox type="checkbox" value={value} {...register} />
      <StyledCheckbox />
      <LabelText>{typeof label === "function" ? <label /> : label}</LabelText>
    </CheckBoxWrapper>
  );
};

export const LabelText = styled.span`
  margin-left: 8px;
  color: #343741;
  font-size: 14px;
  white-space: nowrap;
`;

export const CheckBoxWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const HiddenCheckbox = styled.input`
  display: none;
`;

export const StyledCheckbox = styled.div`
  width: 44px;
  height: 22px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  padding: 1.5px;
  display: flex;
  align-items: center;
  ${HiddenCheckbox}:disabled + & {
    background: #e6e6e6;
    cursor: not-allowed;
  }
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    background: #ffffff;
    height: 100%;
    aspect-ratio: 1 / 1;
    transition: all 100ms;
  }
  ${HiddenCheckbox}:checked + & {
    background: #2277cc;
    &::after {
      margin-left: 2.5px;
    }
    &::before {
      margin-left: 0.5px;
      content: "";
      display: block;
      color: #ffffff;
      height: 100%;
      aspect-ratio: 1 / 1;
      background-image: url('data:image/svg+xml;utf-8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.50025 8.00025C4.37225 8.00025 4.24425 7.95125 4.14625 7.85425L0.14625 3.85425C-0.04875 3.65825 -0.04875 3.34225 0.14625 3.14625C0.34225 2.95125 0.65825 2.95125 0.85425 3.14625L4.50025 6.79325L11.1462 0.14625C11.3422 -0.04875 11.6582 -0.04875 11.8542 0.14625C12.0492 0.34225 12.0492 0.65825 11.8542 0.85425L4.85425 7.85425C4.75625 7.95125 4.62825 8.00025 4.50025 8.00025Z" fill="white"/></svg>');
      background-repeat: no-repeat;
      background-size: 12px;
      background-position: center;
    }
  }
  ${HiddenCheckbox}:checked:disabled + & {
    background: #a8c6e6;
  }
`;
