import { atomFamily, atomWithStorage } from "jotai/utils";
import { GradeByAmountsSchemaType } from "@shared/validator/features/invoiceCSVCustomInfo";

/**
 * "pta-amounts-${PTAInvoiceProductId}" のキーで保存/読込を行う。
 */
export const amountsByGradeFamilyAtom = atomFamily(
  (PTAInvoiceProductId: string) =>
    atomWithStorage<GradeByAmountsSchemaType | null>(
      `pta-amounts-${PTAInvoiceProductId}`,
      null
    )
);
