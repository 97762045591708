import React from "react";
import { SearchIcon } from "src/components/icons/SearchIcon";
import { TextField } from "src/components/form/TextField";
import styled from "styled-components";
import { ChevronUpIcon } from "src/components/icons/ChevronUpIcon";
import { ChevronDownIcon } from "src/components/icons/ChevronDownIcon";
import { PcViewOnly } from "src/components/Responsive";

type Props = {
  searchText: string;
  setSearchText: (searchText: string) => void;
  onPrevResult?: () => void;
  onNextResult?: () => void;
  resultCount?: number;
};

export const SearchInput = ({
  searchText,
  setSearchText,
  onPrevResult,
  onNextResult,
  resultCount = 0,
}: Props) => {
  const onChange = (value: string) => {
    setSearchText(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onPrevResult && !e.shiftKey) {
      e.preventDefault();
      onPrevResult();
    }
  };

  return (
    <SearchContainer>
      <TextWrapper>
        <TextField
          value={searchText}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          placeholder={"検索"}
          prepend={<SearchIcon size={20} />}
        />
      </TextWrapper>

      <PcViewOnly>
        <SearchNavigation>
          <NavigationButtons>
            <NavButton
              onClick={onPrevResult}
              disabled={!searchText || resultCount === 0}
            >
              <ChevronUpIcon />
            </NavButton>
            <NavButton
              onClick={onNextResult}
              disabled={!searchText || resultCount === 0}
            >
              <ChevronDownIcon />
            </NavButton>
          </NavigationButtons>
        </SearchNavigation>
      </PcViewOnly>
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TextWrapper = styled.div``;

const SearchNavigation = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const NavigationButtons = styled.div`
  display: flex;
  gap: 4px;
`;

const NavButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  padding: 0;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background: #f5f5f5;
  }
`;
