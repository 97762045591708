import React from "react";

export const DownloadIcon = ({
  size = 15,
  color = "#343741",
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.84975 7.17116L8 9.11428V0.555556C8 0.248731 7.77614 0 7.5 0C7.22386 0 7 0.248731 7 0.555556V9.08224L5.18075 7.17116C4.96775 6.94295 4.62175 6.94295 4.40975 7.17116C4.19675 7.39937 4.19675 7.77171 4.40975 7.99992L6.35975 10.0494C7.00075 10.7384 8.03175 10.7363 8.67075 10.0494L10.6208 7.99992C10.8338 7.77171 10.8338 7.39937 10.6208 7.17116C10.4088 6.94295 10.0627 6.94295 9.84975 7.17116Z"
        fill={color}
      />
      <path
        d="M15 10V13.0059C15 14.1072 14.1055 15 13.0059 15H2.00969C0.908396 15 0.015625 14.1055 0.015625 13.0059V10H1.01562V13.0059C1.01562 13.5539 1.46142 14 2.00969 14H13.0059C13.5539 14 14 13.5542 14 13.0059V10H15Z"
        fill={color}
      />
    </svg>
  );
};
