import { z } from "zod";
import { gradeNumberSchema } from "../rules/child";
import { ptaInvoiceImportRowAmountSchema } from "../models/invoice/ptaInvoiceImportRow.schema";

export const gradeByAmountsSchema = z.array(
  z.object({
    grade: gradeNumberSchema,
    amount: ptaInvoiceImportRowAmountSchema,
  })
);

export type GradeByAmountsSchemaType = z.infer<typeof gradeByAmountsSchema>;
export const invoiceCSVCustomInfoSchema = z.object({
  amountsByGrades: gradeByAmountsSchema,
  onlyAdmin: z.boolean(),
  PTAInvoiceProductId: z.string().uuid(),
});

export type InvoiceCSVCustomInfoSchemaType = z.infer<
  typeof invoiceCSVCustomInfoSchema
>;

export const invoiceCSVCustomInfoSchemaForServer = z.object({
  filter: z.string(),
});

export type InvoiceCSVCustomInfoSchemaForServerType = z.infer<
  typeof invoiceCSVCustomInfoSchemaForServer
>;
