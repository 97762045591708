import { isInternalRoleAdmin } from "@shared/utils/internalUserRole";
import React from "react";
import { exportCampaignRecipientCSV } from "src/apiClients/campaignRecipient";
import { Button } from "src/components/Button";
import { useGetCampaignRecipientList } from "src/hooks/query/campaignRecipient";
import { useLoginUser } from "src/hooks/recoil/user";
import { useFileDownload } from "src/hooks/useFileDownload";
import { toInputDateFormatYearMonth } from "src/utils/time";
import styled from "styled-components";

function Campaign() {
  const { loginUser } = useLoginUser();
  const { download } = useFileDownload();
  const { campaignRecipientList } = useGetCampaignRecipientList();
  if (!isInternalRoleAdmin(loginUser?.internalRole)) {
    return <div>権限がありません</div>;
  }
  const onDownloadCSVSubmit = async () => {
    const blob = await exportCampaignRecipientCSV();
    const date = toInputDateFormatYearMonth(new Date());
    const fileName = `【キャンペーン応募者名簿】_${date}.csv`;
    download(blob, fileName);
  };

  return (
    <>
      <Button onClick={onDownloadCSVSubmit}>CSVダウンロード</Button>
      <Table>
        <thead>
          <tr>
            <Th>受付番号</Th>
            <Th>名前</Th>
            <Th>メールアドレス</Th>
            <Th>申込日時</Th>
            <Th>アカウントID</Th>
          </tr>
        </thead>
        <tbody>
          {campaignRecipientList.map((recipient) => (
            <tr key={recipient.id}>
              <Td>{recipient.receptionNumber}</Td>
              <Td>{recipient.name}</Td>
              <Td>{recipient.email}</Td>
              <Td>{new Date(recipient.createdAt).toLocaleString()}</Td>
              <Td>{recipient.accountId}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default Campaign;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const Th = styled.th`
  background-color: #f5f5f5;
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ddd;
`;
