import { useAtom } from "jotai";
import { amountsByGradeFamilyAtom } from "./atom";

/**
 * PTAInvoiceProductIdの amountsByGrade state を返す。
 */
export const usePTAInvoiceAmountsByGrade = (PTAInvoiceProductId: string) => {
  const [amountsByGrade, setAmountsByGrade] = useAtom(
    amountsByGradeFamilyAtom(PTAInvoiceProductId)
  );

  return {
    amountsByGrade,
    setAmountsByGrade,
  };
};
