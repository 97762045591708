import React, { memo } from "react";
import styled from "styled-components";

type Props = {
  count: number;
  current: number;
};

export const Stepper = memo(({ count, current }: Props) => {
  return (
    <Container>
      {Array.from({ length: count }).map((_, index) => (
        <Circle key={index} active={index + 1 <= current}>
          {index + 1}
        </Circle>
      ))}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  gap: 28px;
  width: fit-content;
  flex-wrap: wrap;
`;

const Circle = styled.span<{ active?: boolean }>`
  /* size */
  width: 24px;
  height: 24px;
  /* shape */
  border-radius: 50%;
  /* display */
  display: flex;
  justify-content: center;
  align-items: center;
  /* color */
  color: #fff;
  background-color: ${({ active }) => (active ? "#0077CC" : "#ABB4C4")};
  /* font */
  font-size: 14px;
  /* position */
  position: relative;
  &:not(:first-child)::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    width: 28px;
    height: 2px;
    background-color: ${({ active }) => (active ? "#0077CC" : "#ABB4C4")};
  }
`;
