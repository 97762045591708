import React from "react";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import { PushNotificationEnabled } from "./PushNotificationEnabled";

const onBoardingAndroid_2_4_1 = getStaticImageUrl("/pwa/android/2/2-4-1.png");

const onBoardingAndroid_3_1_1 = getStaticImageUrl("/pwa/android/3/3-1-1.png");
const onBoardingAndroid_3_1_2 = getStaticImageUrl("/pwa/android/3/3-1-2.png");
const onBoardingAndroid_3_1_3 = getStaticImageUrl("/pwa/android/3/3-1-3.png");

export const androidDescription = [
  {
    image: onBoardingAndroid_2_4_1,
    description:
      "次のステップで「通知設定を有効にする」ボタンを押すとこのような表示が2回出るので「許可」をタップ",
  },
  {
    component: <PushNotificationEnabled />,
  },
  {
    image: onBoardingAndroid_3_1_1,
    description: "ブラウザ右上の縦に並んだ3つの点（︙）をタップ",
  },
  {
    image: onBoardingAndroid_3_1_2,
    description: "「アプリをダウンロード」をタップ",
  },
  {
    image: onBoardingAndroid_3_1_3,
    description: "「インストール」をタップ",
  },
];
