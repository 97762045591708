import { BREAKPOINTS } from "src/components/Responsive";
import { colorsPallet } from "src/theme";
import styled from "styled-components";

export const Container = styled.div`
  border-radius: 8px 8px 8px 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 8px;
  background-color: #fff;
  padding-bottom: 8px;
  overflow: hidden;
  position: relative;
`;

export const PaddingWrapper = styled.div`
  height: calc(100svh - 250px);
  overflow-y: auto;
  padding: 0 16px;
  margin-bottom: 64px;
  display: flex;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  width: 600px;
  @media (max-width: ${BREAKPOINTS.SP}) {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 0 16px;
`;

export const SubTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ErrorMessage = styled.span`
  color: ${colorsPallet.danger};
  font-size: 12px;
`;
