import React from "react";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import { PushNotificationEnabled } from "./PushNotificationEnabled";

const onBoardingIOS_1_Chrome_1 = getStaticImageUrl("/pwa/ios/1/1-Chrome-1.png");
const onBoardingIOS_1_Chrome_2 = getStaticImageUrl("/pwa/ios/1/1-Chrome-2.png");
const onBoardingIOS_1Chrome_3 = getStaticImageUrl("/pwa/ios/1/1-Chrome-3.png");

const onBoardingIOS_2_1 = getStaticImageUrl("/pwa/ios/2/2-1.png");

const onBoardingIOS_3_1 = getStaticImageUrl("/pwa/ios/3/3-1.png");

export const iOSDescription = [
  {
    image: onBoardingIOS_1_Chrome_1,
    description: "右上の「共有マーク」をタップ",
  },
  {
    image: onBoardingIOS_1_Chrome_2,
    description: "「ホームに追加」をタップ",
  },
  {
    image: onBoardingIOS_1Chrome_3,
    description: "右上の「追加」をタップ",
  },
  {
    image: onBoardingIOS_2_1,
    description: "アプリから再度アクセスする",
  },
  {
    image: onBoardingIOS_3_1,
    description:
      "次のステップで「通知設定を有効にする」ボタンを押すとこのような表示が出るので「許可」をタップ",
  },
  {
    component: <PushNotificationEnabled />,
  },
];
