import React, { useState } from "react";
import styled from "styled-components";
import { ModalBody, ModalPortal } from "src/components/Modal";
import { Button } from "src/components/Button";
import { ArrowLeftIcon } from "src/components/icons/ArrowLeftIcon";

type ModalContentProps = {
  description: {
    image?: string;
    component?: React.ReactNode;
    description?: string;
  }[];
  close: () => void;
};

export const PWAModalContent = ({ description, close }: ModalContentProps) => {
  const [currentStep, setCurrentStep] = useState(-1);

  const handleCountReset = () => {
    setCurrentStep(0);
  };
  const handleNext = () => {
    if (currentStep < description.length - 1) {
      setCurrentStep((prev: number) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep((prev: number) => prev - 1);
    }
  };
  const handleClose = () => {
    close();
    setCurrentStep(0);
  };
  const isLastStep = currentStep === description.length - 1;

  return (
    <ModalPortal onClose={handleClose}>
      <ModalBody>
        <StepContent>
          <BackButton
            onClick={handlePrevious}
            disabled={currentStep === -1 || currentStep === 0}
          >
            <ArrowLeftIcon size={25} /> 戻る
          </BackButton>
          {currentStep >= 0 ? (
            <>
              {description[currentStep].component &&
                description[currentStep].component}
              {description[currentStep].image && (
                <ImageContainer>
                  <StepImage src={description[currentStep].image || ""} />
                </ImageContainer>
              )}
              <Text>{description[currentStep].description}</Text>
              <ButtonContainer>
                {isLastStep ? (
                  <>
                    <StartButton onClick={handleClose}>
                      説明を閉じる
                    </StartButton>
                    <CloseButton color="subPrimary" onClick={handleCountReset}>
                      もう一度確認する
                    </CloseButton>
                  </>
                ) : (
                  <StartButton color="subPrimary" onClick={handleNext}>
                    次へ
                  </StartButton>
                )}
              </ButtonContainer>
            </>
          ) : (
            <>
              {/* //TODO: safari等別ブラウザの対応もする */}
              <Text>
                本手順はGoogle Chrome バージョン120.0以降を前提としています
              </Text>
              <StartButton color="subPrimary" onClick={handleCountReset}>
                確認する
              </StartButton>
            </>
          )}
        </StepContent>
      </ModalBody>
    </ModalPortal>
  );
};

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
`;

const BackButton = styled(Button)`
  position: absolute;
  top: -15px;
  left: -10px;
  display: flex;
  align-items: center;
  color: #36434f;
  &:disabled {
    visibility: hidden;
  }
`;
const ImageContainer = styled.div`
  width: 100%;
  max-width: 200px;
  height: auto;
  aspect-ratio: 3 / 4;
  margin: 10px 0;
  border-radius: 8px;
  overflow: hidden;
`;

const StepImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 200px;
  margin-top: 20px;
`;

const NextButton = styled(Button)`
  width: 100%;
  padding: 12px 24px;
  border: none;
  &:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
`;

const CloseButton = styled(Button)`
  width: 100%;
  padding: 12px 24px;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 1.5;
  margin: 20px 0;
  text-align: center;
`;

const StartButton = styled(NextButton)`
  margin-top: 8px;
`;
