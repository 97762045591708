import { useState, useEffect } from "react";

export const useNotificationPermission = () => {
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(
    "Notification" in window ? Notification.permission : "default"
  );

  useEffect(() => {
    if (!("Notification" in window)) {
      return;
    }

    setIsNotificationEnabled(Notification.permission);

    const handlePermissionChange = () => {
      setIsNotificationEnabled(Notification.permission);
    };

    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then((permissionStatus) => {
          permissionStatus.addEventListener("change", handlePermissionChange);
        });
    }

    return () => {
      if ("permissions" in navigator) {
        navigator.permissions
          .query({ name: "notifications" })
          .then((permissionStatus) => {
            permissionStatus.removeEventListener(
              "change",
              handlePermissionChange
            );
          });
      }
    };
  }, []);

  return isNotificationEnabled;
};
