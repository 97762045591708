import {
  NotificationFrequencyType,
  UpsertNotificationFrequencySchema,
  UpsertNotificationFrequencyType,
} from "@shared/validator/features/notificationFrequency.schema";
import * as client from "./client";

export const fetchNotificationFrequency = async () => {
  const notificationFrequency = await client.get<{}, NotificationFrequencyType>(
    "/notificationFrequency/fetch",
    {}
  );
  return notificationFrequency;
};

export const upsertNotificationFrequency = async (
  args: UpsertNotificationFrequencyType
) => {
  UpsertNotificationFrequencySchema.parse(args);
  const notificationFrequency = await client.post<
    UpsertNotificationFrequencyType,
    NotificationFrequencyType
  >("/notificationFrequency/upsert", args);
  return notificationFrequency;
};
