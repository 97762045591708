import React, { memo } from "react";
import styled from "styled-components";
import { Stepper } from "./Common/Stepper";

type Props = {
  count: number;
  current: number;
  title: string;
};

export const RegisterStepper = memo(({ count, current, title }: Props) => {
  return (
    <Container title={title}>
      <Title>{title}</Title>
      <Stepper count={count} current={current} />
    </Container>
  );
});

const Container = styled.div`
  padding: 16px;
  background-color: #e6f1fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ title }) => (title!.length > 0 ? "8px" : "0")};
`;

const Title = styled.p`
  font-size: 14px;
  text-align: center;
`;
