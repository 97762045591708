import { z } from "zod";

export const NotificationFrequencyType = {
  DEFAULT: "DEFAULT",
  LITE: "LITE",
} as const;

export type NotificationFrequencyType =
  (typeof NotificationFrequencyType)[keyof typeof NotificationFrequencyType];

export const UpsertNotificationFrequencySchema = z.object({
  frequency: z.nativeEnum(NotificationFrequencyType),
});

export type UpsertNotificationFrequencyType = z.infer<
  typeof UpsertNotificationFrequencySchema
>;
