import React, { useCallback, useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { Button } from "src/components/Button";
import { CloseIcon } from "src/components/icons/CloseIcon";
import { FileUpload } from "src/components/icons/FileUpload";
import styled from "styled-components";
import { ErrorMessage } from "./styles";

type CSVFilePickerProps = {
  selectedFile: File | null;
  fileName: string;
  onFileSelect: (file: File | null) => void;
};

function CSVFilePicker({
  selectedFile,
  fileName,
  onFileSelect,
}: CSVFilePickerProps) {
  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        onFileSelect(acceptedFiles[0]);
      }
    },
    [onFileSelect]
  );

  const [errorMessage, setErrorMessage] = useState<string>("");

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    rejectedFiles.forEach(({ file, errors }) => {
      errors.forEach(({ code }) => {
        switch (code) {
          case "file-too-large":
            setErrorMessage(
              `${file.name} のファイルサイズが大きすぎます。10MB以下のファイルをアップロードしてください。`
            );
            break;
          case "file-invalid-type":
            setErrorMessage(`${file.name} はCSVファイルではありません。`);
            break;
          default:
            setErrorMessage("ファイルのアップロードに失敗しました。");
            break;
        }
      });
    });
  };

  const removeFile = () => {
    onFileSelect(null);
  };

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".csv"],
    },
    maxSize: 1 * 1024 * 1024, // 1MB
    multiple: false,
  });

  const acceptStyle = useMemo(
    () => ({
      backgroundColor: "#e6f5ff",
    }),
    []
  );

  const style = useMemo(
    () =>
      ({
        ...(isDragAccept ? acceptStyle : {}),
      } as React.CSSProperties),
    [isDragAccept, acceptStyle]
  );

  return (
    <FilePickerSection>
      <FilePicker>
        <FilePickerRoot {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <NotMobileViewOnly>
            <FilePickerInner>
              <FileUpload size={36} />
              {selectedFile ? (
                <FilePickerSelectedFile>
                  <div>
                    選択中のCSVファイル：
                    <FilePickerEmp>{fileName}</FilePickerEmp>
                  </div>
                  <FileRemoveButton
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      removeFile();
                    }}
                  >
                    <CloseIcon color="#343741" />
                  </FileRemoveButton>
                </FilePickerSelectedFile>
              ) : (
                <>
                  <p>
                    <FilePickerEmp>CSVファイルを選択</FilePickerEmp>
                    するか、ドラッグアンドドロップでCSVファイルを添付できます
                  </p>
                </>
              )}
            </FilePickerInner>
          </NotMobileViewOnly>
          <MobileViewOnly>
            <Button size="small" color="subPrimary" disabled={!!selectedFile}>
              CSVファイルを追加
            </Button>
          </MobileViewOnly>
        </FilePickerRoot>
      </FilePicker>
      <MobileViewOnly>
        {selectedFile && (
          <SelectedFile>
            <FilePreview>
              <FileLabel>{fileName}</FileLabel>
              <FileRemoveButton type="button" onClick={removeFile}>
                <CloseIcon color="#343741" />
              </FileRemoveButton>
            </FilePreview>
          </SelectedFile>
        )}
      </MobileViewOnly>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </FilePickerSection>
  );
}

const FilePicker = styled.div`
  width: 100%;
  @media (max-width: 480px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const FilePickerRoot = styled.div`
  width: 100%;
  @media (max-width: 480px) {
    width: fit-content;
  }
`;

const FilePickerSection = styled.div`
  width: 100%;
`;

const FilePickerEmp = styled.span`
  color: #005ec4;
  font-weight: bold;
  cursor: pointer !important;
`;

const FilePickerInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  height: 100px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  color: #757575;
  font-size: 14px;
  padding: 8px 16px;
`;

const FilePreview = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #ececec;
  background-color: #ffffff;
  margin-top: 8px;
`;

const NotMobileViewOnly = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`;

const MobileViewOnly = styled.div`
  @media (min-width: 480px) {
    display: none;
    @media (max-width: 480px) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const SelectedFile = styled.div`
  margin-top: 8px;
`;

const FileLabel = styled.span`
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FileRemoveButton = styled(Button)`
  width: 16px;
  height: 16px;
  background-color: transparent;
  padding: 0;
  margin-left: auto;
`;

const FilePickerSelectedFile = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #ececec;
  background-color: #ffffff;
  padding: 0 4px;
`;

export default CSVFilePicker;
