import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import {
  fetchNotificationFrequency,
  upsertNotificationFrequency,
} from "src/apiClients/notificationFrequency";
import { QueryOptions } from "./QueryOptions";
import { useApiContext } from "src/apiClients/client";

export const useGetNotificationFrequency = (
  queryOptions: QueryOptions = {}
) => {
  const { apiContext } = useApiContext();
  const query = useQuery({
    queryKey: ["api", "notificationFrequency", "fetch", apiContext],
    queryFn: async () => {
      return await fetchNotificationFrequency();
    },
    ...queryOptions,
  });

  return {
    notificationFrequency: useMemo(() => query.data, [query.data]),
    ...query,
  };
};

export const useUpsertNotificationFrequency = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: upsertNotificationFrequency,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["api", "notificationFrequency", "fetch"],
      });
    },
  });
};
