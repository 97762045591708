import React from "react";
import styled from "styled-components";
import { Button } from "src/components/Button";
import { useGetPTAInvoiceProduct } from "src/hooks/query/invoice/ptaInvoiceProduct";
import { useCurrentUser } from "src/hooks/recoil/user";
import { useGetPTAOrganizationInvoiceAccount } from "src/hooks/query/invoice/ptaOrganizationInvoiceAccount";
import { Link } from "src/components/Link";
import { BreadCrumb } from "src/components/Common/BreadCrumb";
import { PageHeader } from "src/components/Page";
import {
  usePolyfitHistory,
  usePolyfitLocationQuery,
  usePolyfitUrl,
} from "src/hooks/router";
import { useGetPTAParentFamilyInvoiceList } from "src/hooks/query/invoice/ptaParentFamilyInvoices";
import { remindPTAParentFamilyInvoice } from "src/apiClients/ptaParentFamilyInvoice";
import { Tooltip } from "react-tooltip";
import { ResendInvoiceReminderModal } from "./components/ResendInvoiceReminderModal";
import { useModal } from "src/components/Modal";
import { useToast } from "src/components/Toast";
import { logger } from "src/utils/logger";
import { BREAKPOINTS } from "src/components/Responsive";
import { PTAInvoiceDetailContent } from "./components/PTAInvoiceDetailContent";

export function PTAInvoiceProductPage() {
  const history = usePolyfitHistory();
  const toast = useToast();
  const currentUser = useCurrentUser();
  const { ptaOrganizationInvoiceAccount } = useGetPTAOrganizationInvoiceAccount(
    {
      organizationId: currentUser.account?.organizationId ?? "",
    }
  );
  const { id: invoiceProductId } = usePolyfitLocationQuery(
    "PTA_INVOICE_DETAIL",
    {
      id: "",
    }
  );
  const { ptaInvoiceProduct, isLoading: isLoadingInvoiceProduct } =
    useGetPTAInvoiceProduct(invoiceProductId);
  const { ptaParentFamilyInvoices, isLoading: isLoadingParentFamilyInvoices } =
    useGetPTAParentFamilyInvoiceList(invoiceProductId);
  const backUrl = usePolyfitUrl({ to: "PTA_INVOICE_LIST" });

  const [
    isShownRemindModal,
    { show: showRemindModal, close: closeRemindModal },
  ] = useModal();
  const pendingInvoiceCount = ptaParentFamilyInvoices.filter(
    (invoice) => invoice.status === "PENDING"
  ).length;
  const isBeforeDueDate = ptaInvoiceProduct?.dueDate
    ? new Date() < new Date(ptaInvoiceProduct.dueDate)
    : false;
  const disabledRemind = pendingInvoiceCount === 0 || !isBeforeDueDate;
  const handleRemindInvoice = async () => {
    if (!invoiceProductId || disabledRemind) return;
    try {
      await remindPTAParentFamilyInvoice({
        PTAInvoiceProductId: invoiceProductId,
      });
      toast.success("請求リマインド通知を送信しました。");
    } catch (err) {
      logger.error(err, { ptaInvoiceProduct });
      toast.error("リマインド通知の送信に失敗しました。");
    }
    closeRemindModal();
  };

  const remindTooltipMessage = !isBeforeDueDate
    ? "支払期限を過ぎた請求にはリマインド通知を送信できません。"
    : pendingInvoiceCount === 0
    ? "未払いの請求がありません。"
    : "";

  if (!ptaOrganizationInvoiceAccount?.enabled) {
    return <div>閲覧できません</div>;
  }

  if (!ptaInvoiceProduct && !isLoadingInvoiceProduct) {
    return <div>請求項目が見つかりませんでした</div>;
  }

  return (
    <div>
      <BreadCrumb>
        <Link
          to={{
            to: "PTA_INVOICE_LIST",
          }}
        >
          請求一覧
        </Link>
        <LinkWrap>請求詳細</LinkWrap>
      </BreadCrumb>
      <HeaderRow>
        <div>
          {/* モバイル時のみ戻るボタン表示 */}
          <MobileInvoiceDiv>
            <PageHeader backTo={backUrl}>
              <Title>{`請求詳細: ${ptaInvoiceProduct?.name}`}</Title>
            </PageHeader>
          </MobileInvoiceDiv>
          <PCInvoiceDiv>
            <Title>{`請求詳細: ${ptaInvoiceProduct?.name}`}</Title>
          </PCInvoiceDiv>
        </div>
        <ButtonRow>
          <Button
            fill
            size="large"
            onClick={() => {
              history.push({
                to: "PTA_INVOICE_ADDITIONAL_PROCESS",
                query: { id: invoiceProductId, step: "1" },
              });
            }}
          >
            請求を追加発行
          </Button>
          <div>
            <Button
              fill
              size="large"
              onClick={showRemindModal}
              disabled={disabledRemind}
              data-tooltip-id="remind-tooltip"
            >
              請求リマインド通知
            </Button>
            {disabledRemind && remindTooltipMessage && (
              <Tooltip
                id="remind-tooltip"
                place="bottom"
                content={remindTooltipMessage}
                style={{ maxWidth: "300px" }}
              />
            )}
          </div>
        </ButtonRow>
      </HeaderRow>

      {ptaInvoiceProduct?.isProcessing ? (
        <IsProcessing>
          現在請求作成処理を実行中です。完了までお待ちください。
          作成の完了はメールでお知らせします。
        </IsProcessing>
      ) : (
        <PTAInvoiceDetailContent
          isLoadingParentFamilyInvoices={isLoadingParentFamilyInvoices}
          invoiceProductId={invoiceProductId}
          ptaParentFamilyInvoices={ptaParentFamilyInvoices}
        />
      )}
      {ptaInvoiceProduct && isShownRemindModal && (
        <ResendInvoiceReminderModal
          ptaInvoiceProduct={ptaInvoiceProduct}
          pendingInvoiceCount={pendingInvoiceCount}
          onSubmit={handleRemindInvoice}
          onClose={closeRemindModal}
        />
      )}
    </div>
  );
}

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  font-size: 22px;
  font-weight: bold;
`;

const LinkWrap = styled.span`
  font-weight: bold;
`;

const MobileInvoiceDiv = styled.div`
  @media (min-width: 1279px) {
    display: none;
  }
`;

const PCInvoiceDiv = styled.div`
  padding-bottom: 18px;
  padding-top: 16px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    width: 100%;
    justify-content: flex-end;
  }
`;

const IsProcessing = styled.div`
  background-color: #fff;
  border-radius: 8px;
  font-size: 16px;
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
