import React, { useState } from "react";
import { Button } from "src/components/Button";
import { RegisterStepper } from "src/components/RegisterStepper";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import styled from "styled-components";
import * as Typo from "src/components/Typo";
import { Margin } from "src/components/Margin";
import { colorsPallet } from "src/theme";
import { useGetPTAOrganizationInvoiceAccount } from "src/hooks/query/invoice/ptaOrganizationInvoiceAccount";
import { useCurrentUser } from "src/hooks/recoil/user";
import { useCreatePTAInvoiceImport } from "src/hooks/query/invoice/ptaInvoiceImport";
import { LoadingCard } from "src/components/LoadingCard";
import { generateRandomString } from "@shared/utils/generateRandomString";
import {
  createPTAInvoiceImportSchema,
  ImportRowType,
} from "@shared/validator/features/ptaInvoiceImport.schema";
import {
  CSVParseResult,
  parseInvoiceCSVFile,
} from "./utils/parseInvoiceCSVFile";
import {
  ButtonWrapper,
  ContentWrapper,
  ErrorMessage,
  PaddingWrapper,
  SubTitleWrapper,
} from "./components/styles";
import { Container } from "./components/styles";
import CSVFilePicker from "./components/CSVFilePicker";
import { useToast } from "src/components/Toast";
import { INVOICE_PROCESS_PATH, InvoiceFlowType } from "./utils/types";

const CURRENT_STEP = 3;

type Props = {
  flowType: InvoiceFlowType;
};

export const PTAInvoiceProcessImportPage = ({ flowType }: Props) => {
  const currentUser = useCurrentUser();
  const { mutate: mutateCreateImport, isPending } = useCreatePTAInvoiceImport();
  const history = usePolyfitHistory();
  const { id: invoiceProductId } = usePolyfitLocationQuery(
    INVOICE_PROCESS_PATH[flowType],
    {
      id: "",
    }
  );
  const { ptaOrganizationInvoiceAccount } = useGetPTAOrganizationInvoiceAccount(
    {
      organizationId: currentUser.account?.organizationId ?? "",
    }
  );

  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState("選択されていません");
  const [validatedRows, setValidatedRows] = useState<ImportRowType | null>(
    null
  );

  type ErrorRow = CSVParseResult["errorRows"][0] & { index: number };
  const [errorRows, setErrorRows] = useState<ErrorRow[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const toast = useToast();

  if (!ptaOrganizationInvoiceAccount?.enabled) {
    return <div>閲覧できません</div>;
  }

  const handleFileChange = async (selectedFile: File | null) => {
    setErrorMessage("");
    setValidatedRows(null);
    setErrorRows([]);

    if (!selectedFile) {
      setFileName("選択されていません");
      setFile(null);
      return;
    }
    setFileName(selectedFile.name);
    setFile(selectedFile);

    try {
      const result = await parseInvoiceCSVFile(selectedFile);
      if (result.errorRows.length > 0) {
        setErrorRows(result.errorRows);
        setErrorMessage(
          "一部の行に不備があります。以下のエラーを確認してください。"
        );
      }
      setValidatedRows(result.rows);
    } catch (error) {
      console.error(error);
      setErrorMessage("CSVの読み込み時にエラーが発生しました");
    }
  };

  const prevStep = () => {
    history.push({
      to: INVOICE_PROCESS_PATH[flowType],
      query: { id: invoiceProductId, step: `${CURRENT_STEP - 1}` },
    });
  };

  const nextStep = async () => {
    if (errorRows.length > 0) {
      toast.error("エラーが含まれているため、次へ進めません");
      return;
    }
    if (!file || !validatedRows || validatedRows.length === 0) {
      toast.error("有効なCSVファイルが読み込まれていません");
      return;
    }

    const idempotencyKey = generateRandomString(32);
    const finalData = {
      PTAInvoiceProductId: invoiceProductId,
      idempotencyKey,
      rows: validatedRows,
    };
    const result = createPTAInvoiceImportSchema.safeParse(finalData);
    if (!result.success) {
      const issues = result.error.issues.map((i) => i.message).join(", ");
      toast.error(`CSVインポート処理に失敗しました: ${issues}`);
      return;
    }

    mutateCreateImport(
      {
        PTAInvoiceProductId: invoiceProductId,
        rows: validatedRows,
        idempotencyKey,
        file,
      },
      {
        onSuccess: (data) => {
          const { importId } = data;

          history.push({
            to: INVOICE_PROCESS_PATH[flowType],
            query: {
              id: invoiceProductId,
              importId,
              step: `${CURRENT_STEP + 1}`,
            },
          });
        },
        onError: (error) => {
          toast.error("CSVインポート処理に失敗しました");
          console.error(error);
        },
      }
    );
  };

  return (
    <Container>
      <RegisterStepper count={5} current={CURRENT_STEP} title="" />
      <Margin marginBottom={16} />
      <PaddingWrapper>
        <ContentWrapper>
          <SubTitleWrapper>
            <Typo.Heading3>CSVデータのインポート</Typo.Heading3>
          </SubTitleWrapper>
          <Margin marginBottom={16} />
          <Description>
            編集したCSVファイルを選択してインポートしてください。
          </Description>
          <Margin marginBottom={16} />
          <CSVFilePicker
            selectedFile={file}
            fileName={fileName}
            onFileSelect={handleFileChange}
          />
          {errorMessage && <ErrorMessageBig>{errorMessage}</ErrorMessageBig>}
          {errorRows.length > 0 && (
            <ErrorList>
              {errorRows.map((row, index) => (
                <ErrorItem key={index}>
                  <div>
                    <p>行 {row.index}</p>
                  </div>
                  <p>家族ID: {row.PTAParentFamilyId}</p>
                  <p>保護者名: {row.parentName}</p>
                  <p>子どもID: {row.childId}</p>
                  <p>子ども名: {row.childName}</p>
                  <p>学年: {row.grade ?? "-"}</p>
                  <p>
                    クラス: {isNaN(Number(row.class)) ? "-" : row.class ?? "-"}
                  </p>
                  <p>請求金額: {row.amount}</p>
                  <ErrorMessage>エラー: {row.errorMessage}</ErrorMessage>
                </ErrorItem>
              ))}
            </ErrorList>
          )}
          {file && !errorMessage && errorRows.length === 0 && (
            <SuccessMessage>
              CSVファイルの読み込みに成功しました。
            </SuccessMessage>
          )}
        </ContentWrapper>
      </PaddingWrapper>
      <ButtonWrapper>
        <Button color="text" fill onClick={prevStep} size="large">
          戻る
        </Button>
        <Button
          color="primary"
          fill
          onClick={nextStep}
          size="large"
          disabled={isPending}
        >
          請求内容を確認する
        </Button>
      </ButtonWrapper>
      {isPending && <LoadingCard />}
    </Container>
  );
};

const ErrorList = styled.ul`
  margin-top: 16px;
  padding: 0 16px;
  list-style-type: none;
  color: ${colorsPallet.danger};
`;

const ErrorItem = styled.li`
  font-size: 12px;
  margin-bottom: 8px;
  padding: 8px;
  border: 1px solid ${colorsPallet.danger};
  border-radius: 4px;
  background-color: #ffe6e6;
`;

const ErrorMessageBig = styled(ErrorMessage)`
  font-size: 14px;
`;

const SuccessMessage = styled.p`
  margin: 16px 16px 0 16px;
  font-size: 14px;
  border: 1px solid ${colorsPallet.success};
  color: ${colorsPallet.success};
  border-radius: 4px;
  padding: 8px;
  display: grid;
  place-items: center;
  background-color: #e6ffe6;
`;

const Description = styled.p`
  font-size: 14px;
`;
