import React from "react";
import { useModal } from "src/components/Modal";
import { Button } from "src/components/Button";
import { PWAModalContent } from "./PWAModalContent";
import { checkIsIOS } from "src/features/Profile/NotificationSetting/utils";
import { iOSDescription } from "src/features/Onboarding/PWA/iOSDescription";
import { androidDescription } from "./AndroidDescription";

export const PWAOnboarding = () => {
  const [isModalOpen, { show, close }] = useModal();
  const isIOS = checkIsIOS();

  return (
    <>
      <Button color="subPrimary" onClick={show}>
        PWAのインストール方法
      </Button>
      {isModalOpen &&
        (isIOS ? (
          <PWAModalContent description={iOSDescription} close={close} />
        ) : (
          <PWAModalContent description={androidDescription} close={close} />
        ))}
    </>
  );
};
