import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import { useMessageList } from "../useMessageList";
import { ChatMessage } from "src/features/common/ChatMessage/components/ChatMessage";
import { DateLabel } from "src/features/common/ChatMessage/components/DateLabel";
import type { GroupChatWithOrganization } from "@shared/types/chatRoom";
import type { ChatRoomEntity } from "@shared/types/boardEducation/chatRoom";
import { SystemMessage } from "src/features/common/ChatMessage/components//SystemMessage";
import { ApprovalStatus } from "@shared/types/chatRoom";
import { BREAKPOINTS, Col } from "src/components/Responsive";
import { ChatRoomBackButton } from "src/features/common/ChatMessage/components/ChatRoomBackButton";
import { useFloatingDateLabel } from "src/features/common/ChatMessage/hooks/useFloatingDateLabel";
import { ChatMessages } from "src/features/common/ChatMessage/components/ChatMessages";
import { SearchInput } from "src/features/common/ChatMessage/components/SearchInput";
import { useChatSearchText } from "src/features/common/ChatMessage/hooks/useChatSearchText";

type Props = {
  isOpen: boolean;
  chatRoomParticipantId: string;
  chatRoom: ChatRoomEntity["chatRoom"];
  groupChatWithOrganization: GroupChatWithOrganization;
  lastReadAt: Date;
  lastReadAts: Date[];
  refetch: () => void;
};

export const ChatRoomGroupContent = (props: Props) => {
  const chatRoomInfoRef = useRef<HTMLDivElement>(null);
  const messageListRef = useRef<HTMLDivElement>(null); // チャットメッセージエリアの参照
  const { displayItemList } = useMessageList({
    initLastReadAt: props.lastReadAt,
    messageListRef,
    chatRoomParticipantId: props.chatRoomParticipantId,
  });
  const currentDate = useFloatingDateLabel({
    messageListRef,
    displayItemList,
  }); // メッセージ一覧画面の上部にfixedで表示する日付

  const {
    currentSearchIndex,
    searchResults,
    handlePrevResult,
    handleNextResult,
    searchText,
    setSearchText,
  } = useChatSearchText({
    displayItemList,
  });

  // ChatRoomInfoの高さ分paddingをつける
  const chatRoomInfoHeight = useMemo(() => {
    return chatRoomInfoRef.current?.clientHeight ?? 0;
  }, [chatRoomInfoRef.current]);

  const participantChatRoom = props.chatRoom.ParticipantChatRooms.find(
    (participantChatRoom) =>
      participantChatRoom.participantId === props.chatRoomParticipantId
  );

  if (!displayItemList) return <></>;
  if (
    props.chatRoom.approvalStatus === ApprovalStatus.REJECTED ||
    participantChatRoom?.approvalStatus === ApprovalStatus.REJECTED
  )
    return <></>;

  return (
    <ChatRoomContainer $isOpen={props.isOpen}>
      <ChatRoomInfo ref={chatRoomInfoRef}>
        <Header>
          <FlexWrapper>
            <ChatRoomBackButton targetRoute="BE_CHATROOM_LIST" />
            <Col>
              <HeaderTitle>{props.chatRoom.name}</HeaderTitle>
              <HeaderSubLabel>{props.chatRoom.community?.name}</HeaderSubLabel>
            </Col>

            <SearchInputWrapper>
              <SearchInput
                searchText={searchText}
                setSearchText={setSearchText}
                onPrevResult={handlePrevResult}
                onNextResult={handleNextResult}
                resultCount={searchResults.length}
              />
            </SearchInputWrapper>
          </FlexWrapper>
        </Header>
      </ChatRoomInfo>
      <ChatBox $paddingTop={chatRoomInfoHeight}>
        {currentDate && (
          <FloatWrapper>
            <FloatDateLabel>{currentDate}</FloatDateLabel>
          </FloatWrapper>
        )}
        <ChatMessages messageListRef={messageListRef}>
          {displayItemList.map((item, index) => {
            switch (item.itemType) {
              case "dateLabel":
                return (
                  <DateLabel key={index} data={item} className="date-label" />
                );
              case "systemMessage":
                return <SystemMessage key={index} message={item} />;
              case "userMessage":
                return (
                  <ChatMessage
                    key={index}
                    message={item}
                    participantId={props.chatRoomParticipantId}
                    searchText={searchText}
                    lastReadAts={props.lastReadAts}
                    isGroupChat={true}
                    participantAdminId={props.chatRoom.communityId}
                    currentSearchIndex={currentSearchIndex}
                    searchResults={searchResults}
                  />
                );
            }
          })}
        </ChatMessages>
      </ChatBox>
    </ChatRoomContainer>
  );
};

const ChatRoomContainer = styled.div<{
  $isOpen: boolean;
}>`
  position: relative;
  flex: 1;
  min-width: 0;
  height: 100%;
  max-height: 100%;
  background: #fff;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    display: ${(props) => (props.$isOpen ? "block" : "none")};
  }
`;

const ChatRoomInfo = styled.div`
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChatBox = styled.div<{ $paddingTop: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${(props) =>
    props.$paddingTop ? props.$paddingTop + "px 8px 0 8px" : "60px 8px 0 8px"};
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  & > button:first-child {
    display: none;
    @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
      display: block;
    }
  }
`;

const SearchInputWrapper = styled.div`
  width: 100%;
  max-width: 240px;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    max-width: 150px;
  }
`;

const HeaderTitle = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.05em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    max-width: 100px;
  }
`;

const HeaderSubLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #69707d;
`;

const FloatWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 100;
`;

const FloatDateLabel = styled.div`
  background: white;
  height: 24px;
  font-size: 12px;
  padding: 0 16px;
  border-radius: 24px;
  border: 1px solid rgba(221, 221, 221, 1);
  text-align: center;
  width: fit-content;
  font-weight: 600;
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  line-height: 22px;
`;
