import { PTAInvoiceImportRowSchema } from "@shared/validator/models/invoice/ptaInvoiceImportRow.schema";

export function summarizeInvoiceImport(
  rows: PTAInvoiceImportRowSchema[]
): Map<string, Map<number, PTAInvoiceImportRowSchema[]>> {
  const groupedMap = new Map<
    string,
    Map<number, PTAInvoiceImportRowSchema[]>
  >();

  rows.sort((a, b) => (a.grade ?? 0) - (b.grade ?? 0));

  rows.forEach((row) => {
    if (!groupedMap.has(row.grade?.toString() ?? "")) {
      groupedMap.set(row.grade?.toString() ?? "", new Map());
    }

    const gradeMap = groupedMap.get(row.grade?.toString() ?? "");
    if (!gradeMap) {
      return;
    }
    if (!gradeMap.has(row.amount)) {
      gradeMap.set(row.amount, []);
    }

    gradeMap.get(row.amount)?.push(row);
  });

  // 各 gradeMap を人数の多い順にソート
  groupedMap.forEach((gradeMap, grade) => {
    const sortedGradeMap = new Map(
      [...gradeMap.entries()].sort((a, b) => b[1].length - a[1].length)
    );
    groupedMap.set(grade, sortedGradeMap);
  });

  return groupedMap;
}
