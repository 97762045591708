import { useState, useEffect } from "react";
import { DisplayItem } from "src/features/common/ChatMessage/types";

export const useChatSearchText = (props: {
  displayItemList: DisplayItem[];
}) => {
  const [currentSearchIndex, setCurrentSearchIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState<string[]>([]);

  // 検索結果を更新する関数
  useEffect(() => {
    if (searchText) {
      // 空白のみの場合は検索結果を更新しない
      if (searchText.trim() === "") {
        setSearchResults([]);
        return;
      }
      const results: string[] = props.displayItemList
        ?.filter(
          (item) =>
            item.itemType === "userMessage" &&
            item.value.content.toLowerCase().includes(searchText.toLowerCase())
        )
        .map((item) => {
          if (item.value && typeof item.value !== "string") {
            return item.value.id;
          }
          return "";
        })
        .filter((id) => id !== "")
        .reverse(); // 新しいものを優先に表示するために逆順にする
      setSearchResults(results);
      setCurrentSearchIndex(0);
    } else {
      setSearchResults([]);
      setCurrentSearchIndex(0);
    }
  }, [searchText, props.displayItemList]);

  const handlePrevResult = () => {
    setCurrentSearchIndex((prev) =>
      prev < searchResults.length - 1 ? prev + 1 : 0
    );
  };

  const handleNextResult = () => {
    setCurrentSearchIndex((prev) =>
      prev > 0 ? prev - 1 : searchResults.length - 1
    );
  };

  return {
    currentSearchIndex,
    searchResults,
    handlePrevResult,
    handleNextResult,
    searchText,
    setSearchText,
  };
};
