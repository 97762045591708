import { useMemo } from "react";
import { SchoolType } from "src/@shared/types/organization";
import { getGradesBySchoolType } from "@shared/utils/getGradesBySchoolType";
import { TargetGradesType } from "src/@shared/types/post/api";

export type GradeOption = { id: TargetGradesType; name: string };

export function useGradeOptions(
  schoolType: SchoolType | undefined
): GradeOption[] {
  const gradeOptions = useMemo(() => {
    if (!schoolType) return [];
    const grades = getGradesBySchoolType(schoolType);
    return grades.map((grade) => ({ id: grade, name: grade.toString() }));
  }, [schoolType]);

  return gradeOptions;
}
