import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Spinner } from "src/components/icons/Spinner";
import { PTAParentFamilyInvoiceListType } from "@shared/types/ptaParentFamilyInvoice";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { getClassName } from "src/utils/getChildClass";
import { Link } from "src/components/Link";
import { CancelInvoiceModal } from "./CancelInvoiceModal";
import { colorsPallet } from "src/theme";
import { zIndexes } from "src/zIndex";
import { ModalBackGround } from "src/components/ListPageFilterSetting";
import { useInvalidatePTAParentFamilyInvoiceList } from "src/hooks/query/invoice/ptaParentFamilyInvoices";
import { useToast } from "src/components/Toast";
import { logger } from "src/utils/logger";
import { cancelPTAParentFamilyInvoice } from "src/apiClients/ptaParentFamilyInvoice";
import { getGradeColor } from "src/utils/getGradeColor";
import {
  InvoiceStatus,
  PTAInvoiceDetailContentProps,
  sortChildInvoiceItems,
} from "./PTAInvoiceDetailContent";

export const PTAInvoiceUserListContent = ({
  isLoadingParentFamilyInvoices,
  invoiceProductId,
  ptaParentFamilyInvoices,
}: PTAInvoiceDetailContentProps) => {
  const toast = useToast();
  const { organization } = useGetCurrentOrganization();
  const [selectedChildInvoiceItem, setSelectedChildInvoiceItem] = useState<
    PTAParentFamilyInvoiceListType["PTAChildInvoiceItems"][0] | null
  >(null);
  const [invoiceForCancel, setInvoiceForCancel] =
    useState<PTAParentFamilyInvoiceListType | null>(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const { invalidatePTAParentFamilyInvoiceList } =
    useInvalidatePTAParentFamilyInvoiceList();

  const onClickSetting = useCallback(
    (
      childInvoiceItem: PTAParentFamilyInvoiceListType["PTAChildInvoiceItems"][0]
    ) => {
      if (selectedChildInvoiceItem?.id === childInvoiceItem.id) {
        setSelectedChildInvoiceItem(null);
      } else {
        setSelectedChildInvoiceItem(childInvoiceItem);
      }
    },
    [selectedChildInvoiceItem]
  );

  const onOpenCancelModal = useCallback(() => {
    if (!selectedChildInvoiceItem) return;
    const invoice = ptaParentFamilyInvoices.find(
      (targetInvoice) =>
        targetInvoice.id === selectedChildInvoiceItem.PTAParentFamilyInvoiceId
    );
    if (!invoice) return;

    setInvoiceForCancel(invoice);
    setSelectedChildInvoiceItem(null);
    setIsCancelModalOpen(true);
  }, [selectedChildInvoiceItem, ptaParentFamilyInvoices]);

  const handleCancelSubmit = useCallback(async () => {
    if (!invoiceForCancel) return;

    if (invoiceForCancel.status !== "PENDING") {
      toast.warn("未払いの請求のみキャンセルできます。");
      return;
    }

    try {
      await cancelPTAParentFamilyInvoice({ invoiceId: invoiceForCancel.id });
      invalidatePTAParentFamilyInvoiceList(invoiceProductId);
      toast.warn("請求をキャンセルしました。");
    } catch (err) {
      logger.error(err, { invoiceForCancel });
      toast.error("キャンセルに失敗しました。");
    }

    setIsCancelModalOpen(false);
    setInvoiceForCancel(null);
  }, [
    invoiceForCancel,
    invoiceProductId,
    invalidatePTAParentFamilyInvoiceList,
  ]);

  const handleCloseModal = useCallback(() => {
    setIsCancelModalOpen(false);
    setInvoiceForCancel(null);
  }, []);

  if (isLoadingParentFamilyInvoices) {
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    );
  }

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            <Th>保護者名</Th>
            <Th>子どもごとの内訳</Th>
            <Th>合計請求金額</Th>
            <Th>状況</Th>
            <Th></Th>
          </tr>
        </thead>
        <tbody>
          {ptaParentFamilyInvoices.map((invoice) => (
            <Tr key={invoice.id}>
              <Td>
                <LinkText
                  to={{
                    to: "PTA_LIST_MEMBER",
                    query: {
                      userId:
                        invoice.PTAChildInvoiceItems[0].child.userId ?? "",
                    },
                  }}
                  $place="start"
                >{`${
                  invoice.PTAParentFamily.mainAccount.user.baseInfo?.lastName ||
                  ""
                } ${
                  invoice.PTAParentFamily.mainAccount.user.baseInfo
                    ?.firstName || ""
                }`}</LinkText>
              </Td>
              <Td>
                <ChildInvoiceList>
                  {invoice.PTAChildInvoiceItems.slice()
                    .sort(sortChildInvoiceItems)
                    .map((item) => (
                      <ChildInvoiceItem key={item.id}>
                        <ChildInfo>
                          <ChildName>
                            {`${item.child.childLastName} ${item.child.childFirstName}`}
                          </ChildName>
                          <ChildClass>
                            <GradeLabel
                              style={{
                                backgroundColor: getGradeColor(
                                  item.child.grade
                                ),
                              }}
                            >
                              <span>学年</span>
                              <span>
                                {getGradeLabel(
                                  item.child.grade,
                                  organization?.schoolDisplayType,
                                  true,
                                  true,
                                  "short"
                                )}
                              </span>
                            </GradeLabel>
                            <ClassLabel>
                              <ClassTitle>クラス</ClassTitle>
                              <ClassValue>
                                {getClassName(item.child.class, true)}
                              </ClassValue>
                            </ClassLabel>
                          </ChildClass>
                        </ChildInfo>
                        <ChildAmount>{`${item.amount.toLocaleString()}円`}</ChildAmount>
                      </ChildInvoiceItem>
                    ))}
                </ChildInvoiceList>
              </Td>
              <Td>{`${invoice.unitAmount.toLocaleString()}円`}</Td>
              <Td>
                <StatusBadge $status={invoice.status}>
                  {InvoiceStatus[invoice.status]}
                </StatusBadge>
              </Td>
              <Td>
                <MenuContainer>
                  {invoice.status === "PENDING" && (
                    <SettingButton
                      onClick={() =>
                        onClickSetting(invoice.PTAChildInvoiceItems[0])
                      }
                      $isOpen={
                        selectedChildInvoiceItem?.PTAParentFamilyInvoiceId ===
                        invoice.id
                      }
                    >
                      …
                    </SettingButton>
                  )}

                  {selectedChildInvoiceItem?.PTAParentFamilyInvoiceId ===
                    invoice.id && (
                    <>
                      <ModalBackGround
                        onClick={() => setSelectedChildInvoiceItem(null)}
                      />
                      <EditMenuTab>
                        {invoice.status === "PENDING" && (
                          <SettingEditInfo
                            onClick={
                              invoice.status === "PENDING"
                                ? onOpenCancelModal
                                : undefined
                            }
                          >
                            <DangerText>請求をキャンセル</DangerText>
                          </SettingEditInfo>
                        )}
                      </EditMenuTab>
                    </>
                  )}
                </MenuContainer>
              </Td>
            </Tr>
          ))}
        </tbody>
      </StyledTable>
      {isCancelModalOpen && invoiceForCancel && (
        <CancelInvoiceModal
          invoice={invoiceForCancel}
          onSubmit={handleCancelSubmit}
          onClose={handleCloseModal}
          disabled={invoiceForCancel.status !== "PENDING"}
        />
      )}
    </>
  );
};

const LoadingWrapper = styled.div`
  padding: 24px;
  text-align: center;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Tr = styled.tr`
  &:hover {
    background-color: #f7f8fa;
  }
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #e3e6eb;
`;

const Td = styled.td`
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #e3e6eb;
`;

const LinkText = styled(Link)<{ $place: "start" | "center" | "end" }>`
  padding-right: 16px;
  cursor: pointer;
  display: block;
  text-align: ${(props) => props.$place};
  color: #07c;
`;
const StatusBadge = styled.span<{ $status: string }>`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  background-color: ${({ $status }) =>
    $status === "PENDING"
      ? colorsPallet.danger
      : $status === "PAID"
      ? colorsPallet.success
      : colorsPallet.lightestShade};
  color: ${({ $status }) =>
    $status === "PENDING" ? "#fff" : $status === "PAID" ? "#fff" : "#333"};
`;

const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const SettingButton = styled.button<{ $isOpen: boolean }>`
  width: 40px;
  height: 40px;
  background-color: transparent;
  font-size: 16px;
  color: #343741;
  border: none;
  border-radius: 100%;
  padding-bottom: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f3f4f8;
  }
  background-color: ${(props) => (props.$isOpen ? "#f3f4f8" : "transparent")};
`;

const SettingEditTab = styled.div`
  position: absolute;
  right: 0;
  background-color: #ffffff;
  border-radius: 5px;
  width: 150px;
  border: 1px solid #e4e6f3;
  padding: 5px 0;
`;

const EditMenuTab = styled(SettingEditTab)`
  z-index: ${zIndexes.notModalMenu};
`;

const SettingEditInfo = styled.div`
  font-size: 14px;
  padding: 10px 16px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e4e6f3;
  }
`;

const DangerText = styled.span`
  color: ${colorsPallet.danger};
`;

const ChildInvoiceList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChildInvoiceItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;

const ChildInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChildName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #333;
`;

const ChildClass = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const GradeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #fff;
  padding: 2px 8px;
  border-radius: 12px;
`;

const ClassLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #666;
  background-color: #f5f5f5;
  padding: 2px 8px;
  border-radius: 12px;
`;

const ClassTitle = styled.span`
  color: #999;
`;

const ClassValue = styled.span`
  color: #333;
`;

const ChildAmount = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #333;
  padding: 4px 0;
  text-align: right;
`;
