import { useQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useApiContext } from "src/apiClients/client";
import {
  fetchPushNotification,
  updatePushNotificationSetting,
  upsertPushNotificationToken,
} from "src/apiClients/pushNotification";
import { useToast } from "src/components/Toast";
import { registerServiceWorker, requestForToken } from "src/utils/firebase";

export const usePushNotificationSetting = () => {
  // プッシュ通知の有効状態を取得するクエリ
  const apiContext = useApiContext();
  const toast = useToast();
  const {
    data: isPushNotificationEnabled,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["api", "pushNotification", apiContext],
    queryFn: fetchPushNotification,
    select: (res) => res.isPushNotificationEnabled,
  });
  const [
    canUpdatePushNotificationEnabled,
    setCanUpdatePushNotificationEnabled,
  ] = useState(true);
  // プッシュ通知設定を更新するミューテーション
  const { mutate: updatePushNotificationEnabled, isPending } = useMutation({
    mutationFn: (value: boolean) => {
      if (value) {
        registerServiceWorker();
        return requestForToken().then((token) => {
          if (!token) return;
          upsertPushNotificationToken({ token });
          return updatePushNotificationSetting({
            isPushNotificationEnabled: value,
          });
        });
      } else {
        return updatePushNotificationSetting({
          isPushNotificationEnabled: value,
        });
      }
    },
    onSuccess: () => {
      setCanUpdatePushNotificationEnabled(true);
      refetch();
    },
    onError: (error) => {
      if (error instanceof Error) {
        if (
          error.message ==
          "Messaging: The notification permission was not granted and blocked instead. (messaging/permission-blocked)."
        ) {
          setCanUpdatePushNotificationEnabled(false);
        }
      } else {
        setCanUpdatePushNotificationEnabled(true);
        toast.error("プッシュ通知の設定に失敗しました");
      }
      throw error;
    },
  });

  return {
    isPushNotificationEnabled,
    updatePushNotificationEnabled,
    isLoading,
    isPending,
    canUpdatePushNotificationEnabled,
  };
};
