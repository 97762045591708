import React, { memo } from "react";
import { SidebarMenu } from "./SidebarMenu";
import { useLocation } from "react-router-dom";
import { ShapeIcon } from "../../icons/ShapeIcon";
// import { Onboarding } from "src/features/Onboarding/Onboarding";

export const InternalAdminSidebar = memo(() => {
  const { pathname } = useLocation();
  return (
    <>
      <SidebarMenu
        isActive={pathname.startsWith("/internal")}
        icon={<ShapeIcon />}
        text="INTERNAL"
        menus={[
          {
            text: "組織一覧",
            to: "/internal/organizations",
          },
          {
            text: "コミュニティ一覧",
            to: "/internal/communities",
          },
          {
            text: "未認証ユーザー削除",
            to: "/internal/unverified/deleteUser",
          },
          {
            text: "キャンペーン",
            to: "/internal/campaign",
          },
        ]}
      />
      {/* <Onboarding/> */}
    </>
  );
});
