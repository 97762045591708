import React, { useEffect } from "react";
import { Button } from "src/components/Button";
import { Spinner } from "src/components/icons/Spinner";
import { ModalBody, ModalHeader } from "src/components/Modal";
import { ModalPortal } from "src/components/Modal";
import { useToast } from "src/components/Toast";
import { ModalWrapper } from "src/features/Member/EditBaseInfo";
import {
  checkIsIOS,
  checkIsPWA,
} from "src/features/Profile/NotificationSetting/utils";
import { usePushNotificationSetting } from "src/hooks/query/pushNotification";
import { useCurrentUser } from "src/hooks/recoil/user";
import { useNotificationPermission } from "src/hooks/useNotificationPermissions";
import { colorsPallet } from "src/theme";
import { isCsAdminOrCsOperator, isCsRole } from "src/utils/types/role";
import styled from "styled-components";

function SideBarPushNotifyModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  if (!isOpen) {
    return null;
  }
  const currentUser = useCurrentUser();
  const isCS = isCsRole(currentUser.role);
  const isCSAdminOrOperator = isCsAdminOrCsOperator(currentUser.role);
  const isIOS = checkIsIOS();
  const isPWA = checkIsPWA();
  const toast = useToast();
  const {
    isPushNotificationEnabled,
    updatePushNotificationEnabled,
    isLoading,
    isPending,
  } = usePushNotificationSetting();

  const notificationPermission = useNotificationPermission();
  const isNotificationDenied =
    notificationPermission === "denied" ||
    (isIOS && notificationPermission === "default" && isPWA);

  const NotificationSettingState = () => {
    if (isNotificationDenied) {
      return (
        <SettingErrorDescription>
          通知が無効になっているため、このデバイスで通知を受け取ることができません。
          <br />
          端末の設定から通知を有効にした後、アプリを再起動して再度お試しください。
        </SettingErrorDescription>
      );
    }
  };
  const handleClickEnablePushNotify = async () => {
    if (isIOS && !isPWA) {
      //TODO: iOSのPWAインストールのオンボーディングに遷移する
    } else {
      updatePushNotificationEnabled(true);
    }
  };
  useEffect(() => {
    //isPushNotificationEnabledがtrueになったことを確認してからtoastを表示 & onCloseする
    if (isPushNotificationEnabled) {
      toast.success("プッシュ通知を有効にしました");
      onClose();
    }
  }, [isPushNotificationEnabled]);
  return (
    <>
      {isOpen && (
        <ModalPortal onClose={onClose}>
          <ModalWrapper>
            <ModalHeader>プッシュ通知を受け取る</ModalHeader>
            <ModalBody>
              <ModalContent>
                お使いのスマートフォンやブラウザで通知を受け取りませんか？
                <br />
                {isCS ? (
                  <>
                    {isCSAdminOrOperator ? (
                      <>
                        プッシュ通知を有効にすると、メッセージや募集への応募などの通知を受け取ることができます。
                      </>
                    ) : (
                      <>
                        プッシュ通知を有効にすると、メッセージや新しく作成された募集の通知を受け取ることができます。
                      </>
                    )}
                  </>
                ) : (
                  <>
                    プッシュ通知を有効にすると、連絡の通知を受け取ることができます。
                  </>
                )}
                {isIOS && !isPWA && (
                  <InstallAppMessage>
                    iOSをお使いの場合、先にアプリを追加する必要があります。
                  </InstallAppMessage>
                )}
              </ModalContent>
              <NotificationSettingState />
              <ButtonWrapper>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleClickEnablePushNotify();
                  }}
                  color={"subPrimary"}
                  disabled={isNotificationDenied}
                  fill={true}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {(isLoading || isPending) && <Spinner size={"forSwitch"} />}
                  {isIOS && !isPWA
                    ? "アプリを追加する"
                    : "プッシュ通知を有効にする"}
                </Button>
              </ButtonWrapper>
            </ModalBody>
          </ModalWrapper>
        </ModalPortal>
      )}
    </>
  );
}

export default SideBarPushNotifyModal;

const ModalContent = styled.div`
  font-size: 14px;
  line-height: 1.5;
`;

const ButtonWrapper = styled.div`
  margin-top: 32px;
`;

const InstallAppMessage = styled.div`
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.5;
`;

const SettingErrorDescription = styled.p`
  margin-top: 16px;
  font-family: "Inter";
  font-style: normal;
  font-size: 12px;
  line-height: 19px;
  color: ${colorsPallet.danger};
`;
