import { isInternalRoleAdmin } from "@shared/utils/internalUserRole";
import React from "react";
import { useCurrentUser } from "src/hooks/recoil/user";
import { colorsPallet } from "src/theme";
import styled from "styled-components";

function SideBarPushNotifyBadge({
  setIsPushNotifyModalOpen,
}: {
  setIsPushNotifyModalOpen: (isOpen: boolean) => void;
}) {
  const currentUser = useCurrentUser();
  if (!isInternalRoleAdmin(currentUser.internalRole)) {
    return null;
  }

  return (
    <>
      <PushNotifyBadgeWrapper>
        <PushNotifyBadge onClick={() => setIsPushNotifyModalOpen(true)}>
          通知を受け取る
        </PushNotifyBadge>
      </PushNotifyBadgeWrapper>
    </>
  );
}

export default SideBarPushNotifyBadge;

const PushNotifyBadgeWrapper = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  padding: 8px 0;
`;
const PushNotifyBadge = styled.button`
  cursor: pointer;
  border: none;
  background-color: ${colorsPallet.success};
  color: #fff;
  font-size: 14px;
  border-radius: 24px;
  padding: 8px 12px;
`;
