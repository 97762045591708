import React, { useState } from "react";
import SideBarPushNotifyBadge from "./SideBarPushNotifyBadge";
import SideBarPushNotifyModal from "./SideBarPushNotifyModal";
import { usePushNotificationSetting } from "src/hooks/query/pushNotification";

export const SideBarPushNotify = () => {
  const [isPushNotifyModalOpen, setIsPushNotifyModalOpen] = useState(false);
  const { isPushNotificationEnabled } = usePushNotificationSetting();

  return (
    <>
      {!isPushNotificationEnabled && (
        <SideBarPushNotifyBadge
          setIsPushNotifyModalOpen={setIsPushNotifyModalOpen}
        />
      )}
      <SideBarPushNotifyModal
        isOpen={isPushNotifyModalOpen}
        onClose={() => setIsPushNotifyModalOpen(false)}
      />
    </>
  );
};
