import { z } from "zod";
import { ptaInvoiceImportRowSchema } from "../models/invoice/ptaInvoiceImportRow.schema";
import { zRequiredString } from "../rules";

export const getPTAInvoiceImportRowsSchema = z.object({
  importId: z.string().uuid(),
  PTAInvoiceProductId: z.string().uuid(),
});
export type GetPTAInvoiceImportRowsType = z.infer<
  typeof getPTAInvoiceImportRowsSchema
>;

export const importRowSchema = z
  .array(ptaInvoiceImportRowSchema.omit({ id: true, isValid: true }))
  .min(1, "有効な請求データがありません");
export type ImportRowType = z.infer<typeof importRowSchema>;
export const createPTAInvoiceImportSchema = z.object({
  PTAInvoiceProductId: z.string().uuid(),
  idempotencyKey: zRequiredString,
  rows: importRowSchema,
});
export type CreatePTAInvoiceImportType = z.infer<
  typeof createPTAInvoiceImportSchema
>;
export const postPTAInvoiceImportSchema = z.object({
  data: zRequiredString,
});
export type PostPTAInvoiceImportType = z.infer<
  typeof postPTAInvoiceImportSchema
>;

export const createPTAInvoiceImportResponseSchema = z.object({
  importId: zRequiredString,
});
export type CreatePTAInvoiceImportResponseSchema = z.infer<
  typeof createPTAInvoiceImportResponseSchema
>;

export const getPTAInvoiceImportRowsResponseSchema = z.object({
  id: z.string().uuid(),
  idempotencyKey: zRequiredString,
  organizationId: zRequiredString,
  PTAInvoiceProductId: z.string().uuid(),
  status: z.enum(["UPLOADED", "EXECUTING", "DONE"]),
  rows: z.array(ptaInvoiceImportRowSchema),
});
export type GetPTAInvoiceImportRowsResponseType = z.infer<
  typeof getPTAInvoiceImportRowsResponseSchema
>;
