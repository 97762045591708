import { getDefaultStore } from "jotai";
import { amountsByGradeFamilyAtom } from "./atom";
import { GradeByAmountsSchemaType } from "@shared/validator/features/invoiceCSVCustomInfo";

/**
 * PTAInvoiceProductId 用の atomWithStorage を経由して更新する関数。
 */
export const setAmountsByGradeAtom = (
  PTAInvoiceProductId: string,
  data: GradeByAmountsSchemaType
) => {
  const store = getDefaultStore();
  const targetAtom = amountsByGradeFamilyAtom(PTAInvoiceProductId);
  store.set(targetAtom, data);
};
