export type InvoiceFlowType = "new" | "additional";
export const INVOICE_FLOW_TYPE = {
  NEW: "new",
  ADDITIONAL: "additional",
} as const;

/**
 * newの場合: "PTA_INVOICE_PROCESS"
 * additionalの場合: "PTA_INVOICE_ADDITIONAL_PROCESS"
 */
export type InvoiceProcessPath =
  | "PTA_INVOICE_PROCESS"
  | "PTA_INVOICE_ADDITIONAL_PROCESS";
export const INVOICE_PROCESS_PATH: Record<InvoiceFlowType, InvoiceProcessPath> =
  {
    new: "PTA_INVOICE_PROCESS",
    additional: "PTA_INVOICE_ADDITIONAL_PROCESS",
  };
