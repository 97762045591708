import React, { useCallback, useState } from "react";
import styled from "styled-components";
import {
  ModalBody,
  ModalPortal,
  ModalHeader,
  ModalSubmitButtons,
} from "src/components/Modal";
import { PTAInvoiceProductType } from "@shared/validator/models/invoice/ptaInvoiceProduct.schema";
import { LoadingCard } from "src/components/LoadingCard";

type Props = {
  ptaInvoiceProduct: PTAInvoiceProductType;
  pendingInvoiceCount: number;
  onSubmit: () => Promise<void>;
  onClose: () => void;
};

export const ResendInvoiceReminderModal: React.FC<Props> = ({
  ptaInvoiceProduct,
  pendingInvoiceCount,
  onSubmit,
  onClose,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await onSubmit();
    } finally {
      setIsSubmitting(false);
    }
  }, [onSubmit]);

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>請求リマインド通知</ModalHeader>
      <ModalBody>
        <ModalContent>
          <Description>
            未払いの方へ請求リマインド通知を送信しますか？
          </Description>
          <InfoSection>
            <Label>請求名</Label>
            <InfoText>{ptaInvoiceProduct.name}</InfoText>
          </InfoSection>
          <InfoSection>
            <Label>対象未払い件数</Label>
            <InfoText>{`${pendingInvoiceCount}件`}</InfoText>
          </InfoSection>

          {isSubmitting ? (
            <LoadingCard />
          ) : (
            <ModalSubmitButtons
              submitText="送信"
              submitColor="primary"
              onSubmit={handleSubmit}
              onCancel={onClose}
            />
          )}
        </ModalContent>
      </ModalBody>
    </ModalPortal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: #343741;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.div`
  color: #69707d;
  font-size: 12px;
`;

const InfoText = styled.div`
  color: #343741;
  font-size: 16px;
`;
