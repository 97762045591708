import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  executePTAParentFamilyInvoice,
  getPTAParentFamilyInvoiceList,
} from "src/apiClients/ptaParentFamilyInvoice";
import { logger } from "src/utils/logger";
import { CreatePTAParentFamilyInvoiceType } from "@shared/validator/features/ptaParentFamilyInvoice.schema";

export function useGetPTAParentFamilyInvoiceList(ptaInvoiceProductId?: string) {
  const query = useQuery({
    queryKey: ["api", "ptaParentFamilyInvoice", "list", ptaInvoiceProductId],
    queryFn: async () => {
      if (!ptaInvoiceProductId) return [];
      return await getPTAParentFamilyInvoiceList({
        ptaInvoiceProductId,
      });
    },

    enabled: !!ptaInvoiceProductId,
  });

  return {
    ptaParentFamilyInvoices: query.data ?? [],
    ...query,
  };
}

export function useInvalidatePTAParentFamilyInvoiceList() {
  const queryClient = useQueryClient();

  function invalidatePTAParentFamilyInvoiceList(ptaInvoiceProductId?: string) {
    queryClient.invalidateQueries({
      queryKey: ["api", "ptaParentFamilyInvoice", "list", ptaInvoiceProductId],
    });
  }

  return { invalidatePTAParentFamilyInvoiceList };
}

export function useExecutePTAParentFamilyInvoice() {
  return useMutation({
    mutationFn: (args: CreatePTAParentFamilyInvoiceType) =>
      executePTAParentFamilyInvoice(args),
    onSuccess: (response) => {
      console.log("請求作成成功:", response);
    },
    onError: (error) => {
      logger.error(error, { message: "請求作成失敗" });
    },
  });
}
