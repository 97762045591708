import React, { memo } from "react";
import { SidebarMenu } from "./SidebarMenu";
import { useLocation } from "react-router-dom";
import { HomeIcon } from "../../icons/Home";
import { MessageIcon } from "../../icons/MessageIcon";
import { BagEditIcon } from "../../icons/BagEditIcon";
import SideBarLineBadge from "./SideBarLineBadge";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { useGetLineAccount } from "src/hooks/query/lineAccount";
import { SideBarPushNotify } from "./pushNotify";

export const CSMemberSidebar = memo(() => {
  const { pathname } = useLocation();
  const { organization } = useGetCurrentOrganization();
  const { lineAccount } = useGetLineAccount();

  return (
    <>
      <SidebarMenu
        to="/resident/recruitment"
        isActive={
          pathname === "/resident/recruitment" ||
          pathname.startsWith("/resident/recruitment/opened/edit") ||
          pathname.startsWith("/resident/recruitment/detail")
        }
        icon={<HomeIcon />}
        text="募集一覧"
      />
      <SidebarMenu
        isActive={
          pathname.startsWith("/resident/recruitment/participate") ||
          pathname.startsWith("/resident/recruitment/apply")
        }
        icon={<BagEditIcon />}
        text="募集"
        menus={[
          {
            text: "応募した募集",
            to: "/resident/recruitment/apply",
          },
          {
            text: "参加する募集",
            to: "/resident/recruitment/participate",
          },
        ]}
      />
      <SidebarMenu
        to="/resident/chat/recruitment"
        isActive={pathname.startsWith("/resident/chat/recruitment")}
        icon={<MessageIcon />}
        text="メッセージ"
      />
      {organization?.lineOption && !lineAccount && <SideBarLineBadge />}
      <SideBarPushNotify />
    </>
  );
});
