import React from "react";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "src/components/Modal";
import styled from "styled-components";
import { Heading3 } from "src/components/Typo";
import { LoadingCard } from "src/components/LoadingCard";

type Props = {
  onClose: () => void;
  onConfirm: () => Promise<void>;
  isPending: boolean;
};

export const PTAConfirmExecutingInvoiceModal = ({
  onClose,
  onConfirm,
  isPending,
}: Props) => {
  const handleSubmit = async () => {
    await onConfirm();
    onClose();
  };

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>請求を確定</ModalHeader>
      <ModalBody>
        <Message>
          請求を確定すると、インポートした内容をもとに請求情報が作成されます。
          <br />
          作成には時間がかかり、完了時にメールでお知らせします。
        </Message>
        <ModalSubmitButtons
          submitText="確定"
          onSubmit={handleSubmit}
          onCancel={onClose}
          disabled={isPending}
        />
        {isPending && <LoadingCard />}
      </ModalBody>
    </ModalPortal>
  );
};

const ModalHeader = styled(Heading3)`
  padding-bottom: 24px;
  display: flex;
`;

const Message = styled.div`
  padding: 16px 0;
  max-width: 432px;
`;
