import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Spinner } from "src/components/icons/Spinner";
import { PTAParentFamilyInvoiceListType } from "@shared/types/ptaParentFamilyInvoice";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { getClassName } from "src/utils/getChildClass";
import { Link } from "src/components/Link";
import { Button } from "src/components/Button";
import { CancelInvoiceModal } from "./CancelInvoiceModal";
import { colorsPallet } from "src/theme";
import { useInvalidatePTAParentFamilyInvoiceList } from "src/hooks/query/invoice/ptaParentFamilyInvoices";
import { useToast } from "src/components/Toast";
import { logger } from "src/utils/logger";
import { cancelPTAParentFamilyInvoice } from "src/apiClients/ptaParentFamilyInvoice";
import { getGradeColor } from "src/utils/getGradeColor";
import {
  InvoiceStatus,
  PTAInvoiceDetailContentProps,
} from "./PTAInvoiceDetailContent";
import { sortChildInvoiceItems } from "./PTAInvoiceDetailContent";

export const PTAInvoiceMobileCard = ({
  isLoadingParentFamilyInvoices,
  invoiceProductId,
  ptaParentFamilyInvoices,
}: PTAInvoiceDetailContentProps) => {
  const toast = useToast();
  const { organization } = useGetCurrentOrganization();
  const [selectedInvoice, setSelectedInvoice] =
    useState<PTAParentFamilyInvoiceListType | null>(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const { invalidatePTAParentFamilyInvoiceList } =
    useInvalidatePTAParentFamilyInvoiceList();

  const handleCancelSubmit = useCallback(async () => {
    if (!selectedInvoice) return;

    if (selectedInvoice.status !== "PENDING") {
      toast.warn("未払いの請求のみキャンセルできます。");
      return;
    }

    try {
      await cancelPTAParentFamilyInvoice({ invoiceId: selectedInvoice.id });
      invalidatePTAParentFamilyInvoiceList(invoiceProductId);
      toast.warn("請求をキャンセルしました。");
    } catch (err) {
      logger.error(err, { selectedInvoice });
      toast.error("キャンセルに失敗しました。");
    }

    setIsCancelModalOpen(false);
    setSelectedInvoice(null);
  }, [selectedInvoice, invoiceProductId, invalidatePTAParentFamilyInvoiceList]);

  const handleCloseModal = useCallback(() => {
    setIsCancelModalOpen(false);
    setSelectedInvoice(null);
  }, []);

  if (isLoadingParentFamilyInvoices) {
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    );
  }

  return (
    <>
      <CardContainer>
        {ptaParentFamilyInvoices.map((invoice) => (
          <Card key={invoice.id}>
            <CardHeader>
              <ParentName>
                <LinkText
                  $place="start"
                  to={{
                    to: "PTA_LIST_MEMBER",
                    query: {
                      userId:
                        invoice.PTAChildInvoiceItems[0].child.userId ?? "",
                    },
                  }}
                >{`${
                  invoice.PTAParentFamily.mainAccount.user.baseInfo?.lastName ||
                  ""
                } ${
                  invoice.PTAParentFamily.mainAccount.user.baseInfo
                    ?.firstName || ""
                }`}</LinkText>
              </ParentName>
              <div>
                <StatusBadge $status={invoice.status}>
                  {InvoiceStatus[invoice.status]}
                </StatusBadge>
              </div>
            </CardHeader>

            <CardContent>
              <TotalAmount>
                <Label>合計請求金額</Label>
                <Amount>{`${invoice.unitAmount.toLocaleString()}円`}</Amount>
              </TotalAmount>

              <ChildrenSection>
                <SectionLabel>子どもごとの内訳</SectionLabel>
                {invoice.PTAChildInvoiceItems.slice()
                  .sort(sortChildInvoiceItems)
                  .map((item) => (
                    <ChildItem key={item.id}>
                      <ChildInfo>
                        <ChildName>
                          {`${item.child.childLastName} ${item.child.childFirstName}`}
                        </ChildName>
                        <ClassInfo>
                          <GradeLabel
                            style={{
                              backgroundColor: getGradeColor(item.child.grade),
                            }}
                          >
                            <span>学年</span>
                            <span>
                              {getGradeLabel(
                                item.child.grade,
                                organization?.schoolDisplayType,
                                true,
                                true,
                                "short"
                              )}
                            </span>
                          </GradeLabel>
                          <ClassLabel>
                            <span>クラス</span>
                            <span>{getClassName(item.child.class, true)}</span>
                          </ClassLabel>
                        </ClassInfo>
                      </ChildInfo>
                      <ChildAmount>{`${item.amount.toLocaleString()}円`}</ChildAmount>
                    </ChildItem>
                  ))}
              </ChildrenSection>
            </CardContent>

            <CardFooter>
              {invoice.status === "PENDING" && (
                <Button
                  fill
                  color="danger"
                  onClick={() => {
                    setSelectedInvoice(invoice);
                    setIsCancelModalOpen(true);
                  }}
                >
                  請求をキャンセル
                </Button>
              )}
            </CardFooter>
          </Card>
        ))}
      </CardContainer>

      {isCancelModalOpen && selectedInvoice && (
        <CancelInvoiceModal
          invoice={selectedInvoice}
          onSubmit={handleCancelSubmit}
          onClose={handleCloseModal}
          disabled={selectedInvoice.status !== "PENDING"}
        />
      )}
    </>
  );
};

const LoadingWrapper = styled.div`
  padding: 24px;
  text-align: center;
`;

const LinkText = styled(Link)<{ $place: "start" | "center" | "end" }>`
  padding-right: 16px;
  cursor: pointer;
  display: block;
  text-align: ${(props) => props.$place};
  color: #07c;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const CardHeader = styled.div`
  padding: 16px;
  gap: 6px;
  display: flex;
  flex-direction: column;
`;

const ParentName = styled.div`
  font-weight: 500;
`;

const StatusBadge = styled.span<{ $status: string }>`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  background-color: ${({ $status }) =>
    $status === "PENDING"
      ? colorsPallet.danger
      : $status === "PAID"
      ? colorsPallet.success
      : colorsPallet.lightestShade};
  color: ${({ $status }) =>
    $status === "PENDING" ? "#fff" : $status === "PAID" ? "#fff" : "#333"};
`;

const CardContent = styled.div`
  padding: 16px;
`;

const TotalAmount = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.div`
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
`;

const Amount = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #333;
`;

const ChildrenSection = styled.div`
  padding-top: 16px;
`;

const SectionLabel = styled.div`
  font-size: 14px;
  color: #666;
  margin-bottom: 12px;
`;

const ChildItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const ChildInfo = styled.div`
  flex: 1;
`;

const ChildName = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const ClassInfo = styled.div`
  display: flex;
  gap: 8px;
`;

const GradeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
`;

const ClassLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #666;
  background-color: #f5f5f5;
  padding: 2px 8px;
  border-radius: 12px;
`;

const ChildAmount = styled.div`
  font-size: 14px;
  font-weight: 500;
  min-width: 80px;
  text-align: right;
`;

const CardFooter = styled.div`
  padding: 16px;
  /* border-top: 1px solid #e3e6eb; */
  display: flex;
  justify-content: flex-end;
`;
