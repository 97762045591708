import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import ExternalLinkIcon from "src/components/icons/ExternalLinkIcon";
import { createPolyfitUrl } from "src/hooks/router";
import { useMessageList } from "../useMessageList";
import { ChatMessage } from "src/features/common/ChatMessage/components/ChatMessage";
import { DateLabel } from "src/features/common/ChatMessage/components/DateLabel";
import {
  BREAKPOINTS,
  Col,
  MobileViewOnly,
  PcViewOnly,
} from "src/components/Responsive";
import { ChatRoomBackButton } from "src/features/common/ChatMessage/components/ChatRoomBackButton";
import { SystemMessage } from "src/features/common/ChatMessage/components//SystemMessage";
import { useFloatingDateLabel } from "src/features/common/ChatMessage/hooks/useFloatingDateLabel";
import { ChatMessages } from "src/features/common/ChatMessage/components/ChatMessages";
import { useChatSearchText } from "src/features/common/ChatMessage/hooks/useChatSearchText";
import { SearchInput } from "src/features/common/ChatMessage/components/SearchInput";

type Props = {
  isOpen: boolean;
  chatRoomId: string;
  chatRoomParticipantId: string;
  lastReadAt: Date;
  recruitmentId: string;
  recruitmentDateAndStatusLabel: string | null;
  recruitmentTitle: string;
  communityName: string;
  isDeletedRecruitment: boolean;
};

export const ChatRoomRecruitmentContent = (props: Props) => {
  const chatRoomInfoRef = useRef<HTMLDivElement>(null);
  const messageListRef = useRef<HTMLDivElement>(null); // チャットメッセージエリアの参照
  const { displayItemList, lastReadAt } = useMessageList({
    initLastReadAt: props.lastReadAt,
    messageListRef,
    chatRoomParticipantId: props.chatRoomParticipantId,
  });
  const currentDate = useFloatingDateLabel({
    messageListRef,
    displayItemList,
  }); // メッセージ一覧画面の上部にfixedで表示する日付

  const {
    currentSearchIndex,
    searchResults,
    handlePrevResult,
    handleNextResult,
    searchText,
    setSearchText,
  } = useChatSearchText({
    displayItemList,
  });

  // ChatRoomInfoの高さ分paddingをつける
  const chatRoomInfoHeight = useMemo(() => {
    return chatRoomInfoRef.current?.clientHeight ?? 0;
  }, [chatRoomInfoRef.current]);

  const applicationDetailUrl = useMemo(() => {
    return createPolyfitUrl({
      to: "BE_ADMIN_RECRUITMENT_DETAIL",
      query: { id: props.recruitmentId },
    });
  }, [props.recruitmentId]);

  if (!displayItemList) return null;

  return (
    <ChatRoom isOpen={props.isOpen}>
      <ChatRoomInfo ref={chatRoomInfoRef}>
        <Header>
          <FlexWrapper>
            <ChatRoomBackButton targetRoute="BE_CHATROOM_LIST" />
            <Col>
              <RecruitmentTitle>{props.recruitmentTitle}</RecruitmentTitle>
              <HeaderSubLabel>{props.communityName}</HeaderSubLabel>
              <HeaderSubLabel>
                {props.recruitmentDateAndStatusLabel}
              </HeaderSubLabel>
            </Col>
          </FlexWrapper>
          <PcViewOnly>
            <FlexWrapper>
              <SearchInputWrapper>
                <SearchInput
                  searchText={searchText}
                  setSearchText={setSearchText}
                  onPrevResult={handlePrevResult}
                  onNextResult={handleNextResult}
                  resultCount={searchResults.length}
                />
              </SearchInputWrapper>
              {!props.isDeletedRecruitment && (
                <LinkWrapper>
                  <a
                    href={applicationDetailUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExternalLinkIcon />
                  </a>
                </LinkWrapper>
              )}
            </FlexWrapper>
          </PcViewOnly>
        </Header>

        <MobileViewOnly>
          <FlexWrapper>
            <SearchInputWrapperForMobile>
              <SearchInput
                searchText={searchText}
                setSearchText={setSearchText}
                onPrevResult={handlePrevResult}
                onNextResult={handleNextResult}
                resultCount={searchResults.length}
              />
            </SearchInputWrapperForMobile>
            {!props.isDeletedRecruitment && (
              <LinkWrapper>
                <a href={applicationDetailUrl} target="_blank" rel="noreferrer">
                  <ExternalLinkIcon />
                </a>
              </LinkWrapper>
            )}
          </FlexWrapper>
        </MobileViewOnly>
      </ChatRoomInfo>
      <ChatBox paddingTop={chatRoomInfoHeight}>
        {currentDate && (
          <FloatWrapper>
            <FloatDateLabel>{currentDate}</FloatDateLabel>
          </FloatWrapper>
        )}
        <ChatMessages messageListRef={messageListRef}>
          {displayItemList.map((item, index) => {
            switch (item.itemType) {
              case "dateLabel":
                return (
                  <DateLabel key={index} data={item} className="date-label" />
                );
              case "userMessage":
                return (
                  <ChatMessage
                    key={index}
                    message={item}
                    searchText={searchText}
                    participantId={props.chatRoomParticipantId}
                    lastReadAt={lastReadAt}
                    currentSearchIndex={currentSearchIndex}
                    searchResults={searchResults}
                  />
                );
              case "systemMessage":
                return <SystemMessage key={index} message={item} />;
            }
          })}
        </ChatMessages>
      </ChatBox>
    </ChatRoom>
  );
};

const LinkWrapper = styled.div`
  display: flex;
  height: 27px;
  padding: 1px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  gap: 4px;
  text-align: center;
  line-height: 18px; /* 150% */
  margin-left: auto;
  cursor: pointer;
`;

const ChatRoom = styled.div<{
  isOpen: boolean;
}>`
  position: relative;
  flex: 1;
  min-width: 0;
  height: 100%;
  max-height: 100%;
  background: #fff;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    display: ${(props) => (props.isOpen ? "block" : "none")};
  }
`;

const ChatRoomInfo = styled.div`
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChatBox = styled.div<{ paddingTop: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${(props) =>
    props.paddingTop ? props.paddingTop + "px 8px 0 8px" : "60px 8px 0 8px"};
  height: 100%;
`;

const RecruitmentTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #313131;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  gap: 8px;
  & > button {
    display: none;
    @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
      display: block;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  & > button {
    display: none;
    @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
      display: block;
    }
  }
`;

const SearchInputWrapper = styled.div`
  width: 100%;
  max-width: 240px;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    max-width: 150px;
  }
`;

const SearchInputWrapperForMobile = styled.div`
  width: 100%;
`;

const HeaderSubLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #69707d;
`;

const FloatWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 100;
`;

const FloatDateLabel = styled.div`
  background: white;
  height: 24px;
  font-size: 12px;
  padding: 0 16px;
  border-radius: 24px;
  border: 1px solid rgba(221, 221, 221, 1);
  text-align: center;
  width: fit-content;
  font-weight: 600;
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  line-height: 22px;
`;
