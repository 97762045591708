import React from "react";
import styled from "styled-components";
import { Button } from "src/components/Button";
import { useGetPTAInvoiceProductList } from "../../../../hooks/query/invoice/ptaInvoiceProduct";
import { PTAInvoiceProductListContent } from "./PTAInvoiceProductListContent";
import { useCurrentUser } from "src/hooks/recoil/user";
import { useGetPTAOrganizationInvoiceAccount } from "src/hooks/query/invoice/ptaOrganizationInvoiceAccount";
import { usePolyfitHistory } from "src/hooks/router";

export function PTAInvoiceProductListPage() {
  const currentUser = useCurrentUser();
  const { ptaOrganizationInvoiceAccount } = useGetPTAOrganizationInvoiceAccount(
    {
      organizationId: currentUser.account?.organizationId ?? "",
    }
  );
  const history = usePolyfitHistory();

  const { ptaInvoiceProducts, isLoading } = useGetPTAInvoiceProductList();
  if (!ptaOrganizationInvoiceAccount?.enabled) {
    return <div>閲覧できません</div>;
  }

  const handleCreateNewInvoiceProduct = () => {
    history.push({
      to: "PTA_INVOICE_PROCESS",
      query: { id: "", step: "1" },
    });
  };

  return (
    <div>
      <HeaderRow>
        <Title>請求管理</Title>
        <Button fill size="large" onClick={handleCreateNewInvoiceProduct}>
          請求項目を作成
        </Button>
      </HeaderRow>

      <TableWrapper>
        <PTAInvoiceProductListContent
          isLoading={isLoading}
          ptaInvoiceProducts={ptaInvoiceProducts}
        />
      </TableWrapper>
    </div>
  );
}

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.h1`
  font-size: 22px;
  font-weight: bold;
`;

const TableWrapper = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
`;
