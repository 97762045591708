import React from "react";
import { usePolyfitLocationQuery } from "src/hooks/router";
import styled from "styled-components";
import { PTAInvoiceProcessImportPage } from "./PTAInvoiceProcessImportPage";
import { PTAInvoiceProcessImportReviewPage } from "./PTAInvoiceProcessImportReviewPage";
import { PTAInvoiceProcessImportCompletePage } from "./PTAInvoiceProcessImportCompletePage";
import { PTAInvoiceProcessCreateNewPage } from "./PTAInvoiceProcessCreateNewPage";
import { PTAInvoiceProcessDownloadPage } from "./PTAInvoiceProcessDownloadPage";
import { useGetPTAInvoiceProduct } from "src/hooks/query/invoice/ptaInvoiceProduct";
import { LoadingCard } from "src/components/LoadingCard";
import { INVOICE_FLOW_TYPE } from "./utils/types";

export const PTAInvoiceAdditionalProcessPage = () => {
  const { id: invoiceProductId, step } = usePolyfitLocationQuery(
    "PTA_INVOICE_ADDITIONAL_PROCESS",
    {
      id: "",
      step: "1",
    }
  );

  if (!invoiceProductId) {
    return <div>請求情報が見つかりません。</div>;
  }

  const { ptaInvoiceProduct, isLoading } =
    useGetPTAInvoiceProduct(invoiceProductId);

  if (isLoading) {
    return <LoadingCard />;
  }

  if (!ptaInvoiceProduct) {
    return <div>請求情報が見つかりません。</div>;
  }

  const flowType = INVOICE_FLOW_TYPE.ADDITIONAL;

  return (
    <div>
      <Body>
        {step === "1" && <PTAInvoiceProcessCreateNewPage flowType={flowType} />}
        {step === "2" && <PTAInvoiceProcessDownloadPage flowType={flowType} />}
        {step === "3" && <PTAInvoiceProcessImportPage flowType={flowType} />}
        {step === "4" && (
          <PTAInvoiceProcessImportReviewPage flowType={flowType} />
        )}
        {step === "5" && (
          <PTAInvoiceProcessImportCompletePage flowType={flowType} />
        )}
      </Body>
    </div>
  );
};

const Body = styled.div`
  padding-bottom: 16px;
`;
