import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "./QueryOptions";
import { useApiContext } from "src/apiClients/client";
import { useMemo } from "react";
import { getCampaignRecipientList } from "src/apiClients/campaignRecipient";

export function useGetCampaignRecipientList(queryOptions: QueryOptions = {}) {
  const { apiContext } = useApiContext();
  const query = useQuery({
    queryKey: [
      "api",
      "campaignRecipient",
      "getCampaignRecipientList",
      apiContext,
    ],
    queryFn: async () => {
      return await getCampaignRecipientList();
    },
    ...queryOptions,
  });
  return {
    campaignRecipientList: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
}
