/**
 * 単語をトリムする(半角スペース、全角スペースを削除し、前後の空白を削除)
 * @param word 単語
 * @returns トリム後の単語
 */
export const wordTrim = (word: string) => {
  return word
    .replace(/\s+/g, "")
    .replace(/[\s\u3000]+/g, "")
    .trim();
};
