import React from "react";
import { SwitchForNotificationSetting } from "./SwitchForNotificationSetting";
import styled from "styled-components";
import { ArrowDropDownIcon } from "src/components/icons/ArrowDropDown";
import { useCurrentUser } from "src/hooks/recoil/user";
import { isCsAdminRole } from "src/utils/types/role";
import {
  useGetNotificationFrequency,
  useUpsertNotificationFrequency,
} from "src/hooks/query/notificationFrequency";
import { NotificationFrequencyType } from "@shared/validator/features/notificationFrequency.schema";

export const NotificationFrequencySettingSection = () => {
  const currentUser = useCurrentUser();
  const isCSAdmin = isCsAdminRole(currentUser.role);
  const { notificationFrequency } = useGetNotificationFrequency();

  const { mutate: upsertNotificationFrequency } =
    useUpsertNotificationFrequency();
  return (
    <>
      <SettingSection>
        <SettingMainItem>
          <SettingItemLabel>
            <SettingItemName>控えめモード</SettingItemName>
          </SettingItemLabel>
          <SettingAction>
            <div>
              <SwitchForNotificationSetting
                value={notificationFrequency === NotificationFrequencyType.LITE}
                onChange={() => {
                  upsertNotificationFrequency({
                    frequency:
                      notificationFrequency === NotificationFrequencyType.LITE
                        ? NotificationFrequencyType.DEFAULT
                        : NotificationFrequencyType.LITE,
                  });
                }}
              />
            </div>
          </SettingAction>
        </SettingMainItem>
        <SettingItemDetailDescription>
          お知らせの頻度を控えめにします。
        </SettingItemDetailDescription>
        <NotificationFrequencySettingDetail>
          <NotificationFrequencySettingDetailSummary>
            詳細を確認する
            <ArrowDropDownIcon size={24} />
          </NotificationFrequencySettingDetailSummary>
          <SettingItemDetailDescription>
            以下の通知が届かなくなります。
            {isCSAdmin && (
              <ul>
                <ListItem>
                  チャットの通知メール（自身が作成した募集の応募者からのメッセージは除く）
                </ListItem>
                <ListItem>
                  募集応募通知（自身が作成した募集への応募は除く）
                </ListItem>
                <ListItem>応募選考のリマインドメール</ListItem>
              </ul>
            )}
          </SettingItemDetailDescription>
        </NotificationFrequencySettingDetail>
      </SettingSection>
    </>
  );
};

const SettingMainItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SettingItemLabel = styled.div`
  font-weight: 700;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
`;

const SettingItemName = styled.span`
  font-weight: 700;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
`;

const SettingSection = styled.div``;

const SettingItemDetailDescription = styled.p`
  font-weight: 400;
  font-family: "Inter";
  font-style: normal;
  font-size: 12px;
  line-height: 19px;
  color: #808080;
  margin-top: 4px;
`;

const SettingAction = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: 10px;
`;

const NotificationFrequencySettingDetail = styled.details`
  & > summary > svg {
    transition: transform 0.2s;
  }
  &[open] > summary > svg {
    transform: rotate(180deg);
  }
`;

const NotificationFrequencySettingDetailSummary = styled.summary`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #808080;
  font-weight: bold;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 6px;
  list-style: none;
  ::-webkit-details-marker {
    display: none;
  }
`;

const ListItem = styled.li`
  list-style-position: inside;
  margin-left: 8px;
`;
