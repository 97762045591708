import React, { useState, useMemo, useEffect, useRef } from "react";
import styled from "styled-components";
import { createChatMessage } from "src/apiClients/boardEducation/chatMessage";
import { updateParticipantChatRoom } from "src/apiClients/boardEducation/participantChatRoom";
import { useMessageList } from "../useMessageList";
import { ChatMessage } from "src/features/common/ChatMessage/components/ChatMessage";
import { DateLabel } from "src/features/common/ChatMessage/components/DateLabel";
import {
  ChatRoom,
  IndividualChatWithBoardEducation,
} from "@shared/types/chatRoom";
import { SystemMessage } from "src/features/common/ChatMessage/components//SystemMessage";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { ChatRoomBackButton } from "src/features/common/ChatMessage/components/ChatRoomBackButton";
import { ChatInputForm } from "src/components/form/ChatInputForm";
import { BREAKPOINTS } from "src/components/Responsive";
import { useFloatingDateLabel } from "src/features/common/ChatMessage/hooks/useFloatingDateLabel";
import { useCurrentUser } from "src/hooks/recoil/user";
import { updateAdminOnly } from "src/apiClients/boardEducation/chatRoom";
import { ChatMessages } from "src/features/common/ChatMessage/components/ChatMessages";
import { useChatSearchText } from "src/features/common/ChatMessage/hooks/useChatSearchText";
import { SearchInput } from "src/features/common/ChatMessage/components/SearchInput";

type Props = {
  isOpen: boolean;
  chatRoomParticipantId: string;
  chatRoom: ChatRoom;
  individualChatWithBoardEducation: IndividualChatWithBoardEducation;
  lastReadAt: Date;
};

export const ChatRoomIndividualBEContent = (props: Props) => {
  const chatRoomInfoRef = useRef<HTMLDivElement>(null);
  const [input, setInput] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [sending, setSending] = useState(false);
  const messageListRef = useRef<HTMLDivElement>(null); // チャットメッセージエリアの参照
  const toast = useToast();
  const user = useCurrentUser();
  const isAdmin = props.chatRoomParticipantId !== user.id;
  const { displayItemList, lastReadAt, NewMessageNotification } =
    useMessageList({
      initLastReadAt: props.lastReadAt,
      messageListRef,
      chatRoomParticipantId: props.chatRoomParticipantId,
    });
  const currentDate = useFloatingDateLabel({
    messageListRef,
    displayItemList,
  }); // メッセージ一覧画面の上部にfixedで表示する日付

  // チャットルームの最終アクセス日時を更新
  useEffect(() => {
    const updateParticipantChatRoomHandler = () => {
      updateParticipantChatRoom({
        chatRoomId: props.chatRoom.id,
      });
    };

    updateParticipantChatRoomHandler();

    const interval = setInterval(updateParticipantChatRoomHandler, 10000);

    return () => {
      clearInterval(interval);
      updateParticipantChatRoomHandler();
    };
  }, [props.chatRoom]);

  const {
    currentSearchIndex,
    searchResults,
    handlePrevResult,
    handleNextResult,
    searchText,
    setSearchText,
  } = useChatSearchText({
    displayItemList,
  });

  // ChatRoomInfoの高さ分paddingをつける
  const chatRoomInfoHeight = useMemo(() => {
    return chatRoomInfoRef.current?.clientHeight ?? 0;
  }, [chatRoomInfoRef.current]);

  const onSend = async () => {
    if (!canSend) return;
    setSending(true);
    try {
      await createChatMessage(props.chatRoom.id, input, files);
      setInput("");
    } catch (e) {
      if (e instanceof APIError) {
        toast.error(e.message);
        return;
      }
      toast.error("メッセージの送信に失敗しました");
    } finally {
      setSending(false);
    }
  };

  const canSend = useMemo(() => {
    return !sending && (input.length > 0 || files.length > 0);
  }, [sending, input, files]);

  const onCheckedAdminOnly = async (checked: boolean) => {
    await updateAdminOnly(props.chatRoom.id, checked);
  };

  if (!displayItemList) return <></>;
  if (props.chatRoom.approvalStatus === "REJECTED") return <></>;

  return (
    <ChatRoomContainer $isOpen={props.isOpen}>
      <ChatRoomInfo ref={chatRoomInfoRef}>
        <Header>
          <FlexWrapper>
            <ChatRoomBackButton targetRoute="BE_CHATROOM_LIST" />
            <HeaderTitle>
              {props.individualChatWithBoardEducation.account.name}
            </HeaderTitle>
          </FlexWrapper>
          <SearchInputWrapper>
            <SearchInput
              searchText={searchText}
              setSearchText={setSearchText}
              onPrevResult={handlePrevResult}
              onNextResult={handleNextResult}
              resultCount={searchResults.length}
            />
          </SearchInputWrapper>
        </Header>
      </ChatRoomInfo>
      <ChatBox $paddingTop={chatRoomInfoHeight}>
        {currentDate && (
          <FloatWrapper>
            <FloatDateLabel>{currentDate}</FloatDateLabel>
          </FloatWrapper>
        )}
        <ChatMessages messageListRef={messageListRef}>
          {displayItemList.map((item, index) => {
            switch (item.itemType) {
              case "dateLabel":
                return (
                  <DateLabel key={index} data={item} className="date-label" />
                );
              case "systemMessage":
                return <SystemMessage key={index} message={item} />;
              case "userMessage":
                return (
                  <ChatMessage
                    key={index}
                    message={item}
                    participantId={props.chatRoomParticipantId}
                    lastReadAt={lastReadAt}
                    searchText={searchText}
                    currentSearchIndex={currentSearchIndex}
                    searchResults={searchResults}
                  />
                );
            }
          })}
        </ChatMessages>
        <NewMessageNotification />
        <ChatMessageInputContainer>
          <ChatMessageInputArea>
            <ChatInputForm
              isAdmin={isAdmin}
              adminOnly={props.chatRoom.adminOnly}
              onCheckedAdminOnly={onCheckedAdminOnly}
              placeholder="メッセージを入力"
              setValue={setInput}
              setFiles={setFiles}
              canSend={canSend}
              onSend={onSend}
            />
          </ChatMessageInputArea>
        </ChatMessageInputContainer>
      </ChatBox>
    </ChatRoomContainer>
  );
};

const ChatMessageInputContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChatMessageInputArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const ChatRoomContainer = styled.div<{
  $isOpen: boolean;
}>`
  position: relative;
  flex: 1;
  min-width: 0;
  height: 100%;
  max-height: 100%;
  background: #fff;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    display: ${(props) => (props.$isOpen ? "block" : "none")};
  }
`;

const ChatRoomInfo = styled.div`
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChatBox = styled.div<{ $paddingTop: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${(props) =>
    props.$paddingTop ? props.$paddingTop + "px 8px 0 8px" : "60px 8px 0 8px"};
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  & > button:first-child {
    display: none;
    @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
      display: block;
    }
  }
`;

const SearchInputWrapper = styled.div`
  width: 100%;
  max-width: 240px;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    max-width: 150px;
  }
`;

const HeaderTitle = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.05em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    max-width: 100px;
  }
`;

const FloatWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 100;
`;

const FloatDateLabel = styled.div`
  background: white;
  height: 24px;
  font-size: 12px;
  padding: 0 16px;
  border-radius: 24px;
  border: 1px solid rgba(221, 221, 221, 1);
  text-align: center;
  width: fit-content;
  font-weight: 600;
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  line-height: 22px;
`;
