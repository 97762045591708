import React, { useCallback, useMemo, useState } from "react";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "src/components/Modal";
import styled from "styled-components";
import { Margin } from "src/components/Margin";
import { PTAParentFamilyInvoiceListType } from "@shared/types/ptaParentFamilyInvoice";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { getClassName } from "src/utils/getChildClass";
import { LoadingCard } from "src/components/LoadingCard";
import { InvoiceStatus } from "./PTAInvoiceDetailContent";

interface CancelInvoiceModalProps {
  invoice: PTAParentFamilyInvoiceListType;
  onSubmit: () => Promise<void>;
  onClose: () => void;
  disabled: boolean;
}

export const CancelInvoiceModal = ({
  invoice,
  onSubmit,
  onClose,
  disabled,
}: CancelInvoiceModalProps) => {
  const { organization } = useGetCurrentOrganization();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const childInvoiceItemsSortedByGrade = useMemo(() => {
    return [...invoice.PTAChildInvoiceItems].sort((a, b) => {
      if (a.child.grade === undefined && b.child.grade === undefined) return 0;
      if (a.child.grade === undefined) return 1;
      if (b.child.grade === undefined) return -1;
      return a.child.grade - b.child.grade;
    });
  }, [invoice.PTAChildInvoiceItems]);

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await onSubmit();
    } finally {
      setIsSubmitting(false);
    }
  }, [onSubmit]);

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>請求キャンセル</ModalHeader>

      <ModalBody>
        <ModalContent>
          <Description>
            この操作を実行すると、以下の保護者・子どもに紐づく請求がキャンセルされます。
          </Description>

          <InfoSection>
            <Label>保護者名</Label>
            <InfoText>{`${
              invoice.PTAParentFamily.mainAccount.user.baseInfo?.lastName || ""
            } ${
              invoice.PTAParentFamily.mainAccount.user.baseInfo?.firstName || ""
            }`}</InfoText>
          </InfoSection>

          <InfoSection>
            <Label>合計請求金額</Label>
            <InfoText>{`${invoice.unitAmount.toLocaleString()}円`}</InfoText>
          </InfoSection>

          <InfoSection>
            <Label>ステータス</Label>
            <InfoText>{InvoiceStatus[invoice.status]}</InfoText>
          </InfoSection>

          <ChildTable>
            <thead>
              <tr>
                <ChildTh>子ども名</ChildTh>
                <ChildTh>学年</ChildTh>
                <ChildTh>クラス</ChildTh>
                <ChildTh>請求金額</ChildTh>
              </tr>
            </thead>
            <tbody>
              {childInvoiceItemsSortedByGrade.map((item) => (
                <tr key={item.id}>
                  <ChildTd>
                    {item.child.childLastName} {item.child.childFirstName}
                  </ChildTd>
                  <ChildTd>
                    {getGradeLabel(
                      item.child.grade,
                      organization?.schoolDisplayType,
                      true,
                      false,
                      "short"
                    )}
                  </ChildTd>
                  <ChildTd>{getClassName(item.child.class)}</ChildTd>
                  <ChildTd>{`${item.amount.toLocaleString()}円`}</ChildTd>
                </tr>
              ))}
            </tbody>
          </ChildTable>

          <Margin marginTop={16} />
          {isSubmitting ? (
            <LoadingCard />
          ) : (
            <ModalSubmitButtons
              submitColor="danger"
              submitText="請求をキャンセルする"
              onSubmit={handleSubmit}
              disabled={disabled}
              onCancel={onClose}
            />
          )}
        </ModalContent>
      </ModalBody>
    </ModalPortal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: #343741;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.div`
  color: #69707d;
  font-size: 12px;
`;

const InfoText = styled.div`
  color: #343741;
  font-size: 16px;
`;

const ChildTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 8px;
  border: 1px solid #e3e6eb;
  border-radius: 5px;
  overflow: hidden;
`;

const ChildTh = styled.th`
  background-color: #f9fafb;
  padding: 8px;
  font-size: 13px;
  text-align: left;
  border-bottom: 1px solid #e3e6eb;
`;

const ChildTd = styled.td`
  padding: 8px;
  font-size: 13px;
  border-bottom: 1px solid #e3e6eb;
`;
