import React, { useState } from "react";
import { Button } from "src/components/Button";
import { RegisterStepper } from "src/components/RegisterStepper";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import * as Typo from "src/components/Typo";
import { Margin } from "src/components/Margin";
import { useGetPTAOrganizationInvoiceAccount } from "src/hooks/query/invoice/ptaOrganizationInvoiceAccount";
import { useCurrentUser } from "src/hooks/recoil/user";
import {
  ButtonWrapper,
  Container,
  ContentWrapper,
  PaddingWrapper,
  SubTitleWrapper,
} from "./components/styles";
import styled from "styled-components";
import { DownloadIcon } from "src/components/icons/DownloadIcon";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { exportCsvByPTAInvoiceProcess } from "src/apiClients/csv";
import { toInputDateFormatYearMonth } from "src/utils/time";
import { useFileDownload } from "src/hooks/useFileDownload";
import { useToast } from "src/components/Toast";
import { CheckBox } from "src/components/form/CheckBox";
import { colorsPallet } from "src/theme";
import { getIsAmountByGradeValid } from "@shared/utils/isAmountByGradeValid";
import { usePTAInvoiceAmountsByGrade } from "src/hooks/jotai/amountsByGrade/hook";
import { INVOICE_PROCESS_PATH, InvoiceFlowType } from "./utils/types";

type Props = {
  flowType: InvoiceFlowType;
};

export const PTAInvoiceProcessDownloadPage = ({ flowType }: Props) => {
  const currentUser = useCurrentUser();
  const { ptaOrganizationInvoiceAccount } = useGetPTAOrganizationInvoiceAccount(
    {
      organizationId: currentUser.account?.organizationId ?? "",
    }
  );
  const { organization } = useGetCurrentOrganization();
  const schoolDisplayType = organization?.schoolDisplayType;
  const { download } = useFileDownload();
  const toast = useToast();
  const history = usePolyfitHistory();
  const { id: invoiceProductId } = usePolyfitLocationQuery(
    INVOICE_PROCESS_PATH[flowType],
    {
      id: "",
      step: "2",
    }
  );
  const { amountsByGrade } = usePTAInvoiceAmountsByGrade(invoiceProductId);

  const prevStep = () => {
    history.push({
      to: INVOICE_PROCESS_PATH[flowType],
      query: { id: invoiceProductId, step: "1" },
    });
  };

  const nextStep = () => {
    history.push({
      to: INVOICE_PROCESS_PATH[flowType],
      query: { id: invoiceProductId, step: "3" },
    });
  };

  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const [onlyAdmin, setOnlyAdmin] = useState(false);
  const handleClickDownload = async () => {
    if (isCSVLoading) return;

    setIsCSVLoading(true);
    try {
      const blob = await exportCsvByPTAInvoiceProcess({
        amountsByGrades: amountsByGrade ?? [],
        onlyAdmin: onlyAdmin,
        PTAInvoiceProductId: invoiceProductId,
      });
      const date = toInputDateFormatYearMonth(new Date());
      const fileName = `請求用PTA名簿_${date}.csv`;
      download(blob, fileName);
    } catch (e) {
      toast.error("CSVのダウンロードに失敗しました");
    } finally {
      setIsCSVLoading(false);
    }
  };

  const isAmountByGradeValid = getIsAmountByGradeValid(
    amountsByGrade ?? [],
    organization?.schoolType
  );

  if (!ptaOrganizationInvoiceAccount?.enabled) {
    return <div>閲覧できません</div>;
  }

  const titleText =
    flowType === "new"
      ? "CSVテンプレートのダウンロード"
      : "追加請求用CSVテンプレートのダウンロード";

  const descriptionText =
    flowType === "new"
      ? "ダウンロードしたCSVテンプレートを編集することで、個別に請求金額を設定できます。"
      : "ダウンロードしたCSVテンプレートを編集することで、個別に請求金額を設定できます。このCSVには、途中入会された方や、前回の請求がキャンセルとなった方のみが含まれています。";

  return (
    <Container>
      <RegisterStepper count={5} current={2} title="" />
      <Margin marginBottom={16} />
      <PaddingWrapper>
        <ContentWrapper>
          <SubTitleWrapper>
            <Typo.Heading3>{titleText}</Typo.Heading3>
          </SubTitleWrapper>
          <Margin marginBottom={16} />
          <Description>
            {descriptionText}
            <br />
            テンプレートを編集後、次のステップでインポートを行ってください。
            <WarningText>
              CSVファイルのヘッダー行（1行目）は削除しないでください。
            </WarningText>
            <br />
            データの用意ができている場合は、ダウンロードをスキップして次のステップに進んでください。
          </Description>
          <div>
            <AmountsByGradeSection>
              {isAmountByGradeValid ? (
                amountsByGrade?.map((amountByGrade) => (
                  <AmountsByGrade key={amountByGrade.grade}>
                    <div>
                      {getGradeLabel(
                        amountByGrade.grade,
                        schoolDisplayType,
                        true,
                        true,
                        "full",
                        "年生"
                      )}
                    </div>
                    <AmountsByGradeLeft>
                      {amountByGrade.amount.toLocaleString()}円
                    </AmountsByGradeLeft>
                  </AmountsByGrade>
                ))
              ) : (
                <NotEnoughDataSection>
                  学年別請求金額のデータが不正です。テンプレートのダウンロードが必要な場合はもう一度金額を設定してください。
                  <Button fill color="primary" onClick={prevStep}>
                    学年別請求金額設定に戻る
                  </Button>
                </NotEnoughDataSection>
              )}
            </AmountsByGradeSection>
          </div>
          <CheckBox
            label="管理者のみを対象とする"
            checked={onlyAdmin}
            onChange={() => setOnlyAdmin(!onlyAdmin)}
          />
          <DownloadButtonSection>
            <Button
              fill
              color="primary"
              size="large"
              disabled={!isAmountByGradeValid || isCSVLoading}
              onClick={handleClickDownload}
            >
              <ButtonInner>
                <DownloadIcon size={20} color="#fff" />
                CSVテンプレートをダウンロード
              </ButtonInner>
            </Button>
          </DownloadButtonSection>
        </ContentWrapper>
      </PaddingWrapper>
      <ButtonWrapper>
        <Button color="text" fill onClick={prevStep} size="large">
          戻る
        </Button>
        <Button color="primary" fill onClick={nextStep} size="large">
          データのインポートに進む
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

const Description = styled.p`
  font-size: 14px;
`;

const ButtonInner = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DownloadButtonSection = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
`;

const AmountsByGradeSection = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 16px 0;
  padding: 16px 16px;
  background-color: #f5f7fa;
`;

const AmountsByGrade = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 8px 8px;
`;

const AmountsByGradeLeft = styled.div`
  font-weight: bold;
`;

const NotEnoughDataSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  color: ${colorsPallet.danger};
  font-size: 14px;
`;

const WarningText = styled.p`
  color: ${colorsPallet.danger};
  font-weight: bold;
`;
