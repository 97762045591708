import React from "react";
import { usePolyfitLocationQuery } from "src/hooks/router";
import styled from "styled-components";
import { PTAInvoiceProcessImportPage } from "./PTAInvoiceProcessImportPage";
import { PTAInvoiceProcessImportReviewPage } from "./PTAInvoiceProcessImportReviewPage";
import { PTAInvoiceProcessImportCompletePage } from "./PTAInvoiceProcessImportCompletePage";
import { PTAInvoiceProcessCreateNewPage } from "./PTAInvoiceProcessCreateNewPage";
import { PTAInvoiceProcessDownloadPage } from "./PTAInvoiceProcessDownloadPage";
import { INVOICE_FLOW_TYPE } from "./utils/types";

export const PTAInvoiceProcessPage = () => {
  const { step } = usePolyfitLocationQuery("PTA_INVOICE_PROCESS", {
    id: "",
    step: "1",
  });

  const flowType = INVOICE_FLOW_TYPE.NEW;

  return (
    <div>
      <Body>
        {step === "1" && <PTAInvoiceProcessCreateNewPage flowType={flowType} />}
        {step === "2" && <PTAInvoiceProcessDownloadPage flowType={flowType} />}
        {step === "3" && <PTAInvoiceProcessImportPage flowType={flowType} />}
        {step === "4" && (
          <PTAInvoiceProcessImportReviewPage flowType={flowType} />
        )}
        {step === "5" && (
          <PTAInvoiceProcessImportCompletePage flowType={flowType} />
        )}
      </Body>
    </div>
  );
};

const Body = styled.div`
  padding-bottom: 16px;
`;
