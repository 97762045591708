import React, { FC } from "react";

import {
  LabelText,
  StyledCheckbox,
  CheckBoxWrapper,
  HiddenCheckbox,
} from "src/components/form/Switch";
import { Spinner } from "src/components/icons/Spinner";
import styled from "styled-components";

type props = {
  label?: string | FC;
  value: boolean;
  onChange: () => void;
  isLoading?: boolean;
  disabled?: boolean;
};

export const SwitchForNotificationSetting = ({
  label,
  value,
  onChange,
  isLoading = false,
  disabled = false,
}: props) => {
  return (
    <div style={{ position: "relative" }}>
      <CheckBoxWrapper>
        <HiddenCheckbox
          type="checkbox"
          checked={value}
          onChange={onChange}
          disabled={isLoading || disabled}
        />
        <StyledCheckbox />
        {isLoading && (
          <SpinnerWrapper>
            <Spinner size={"forSwitch"} />
          </SpinnerWrapper>
        )}
        <LabelText>{typeof label === "function" ? <label /> : label}</LabelText>
      </CheckBoxWrapper>
    </div>
  );
};

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
