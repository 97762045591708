import React, { useRef } from "react";
import { TextWithHighlight } from "src/components/TextWithHighlight";
import { colorsPallet } from "src/theme";
import styled from "styled-components";

type Props = {
  content: string;
  isMyMessage: boolean;
  searchText: string;
};

export const ParseMessage = ({ content, isMyMessage, searchText }: Props) => {
  const urlRegex = /(https?:\/\/[^\s/$.?#].[^\s]*)/g;
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <MessageContainer ref={containerRef}>
      {content.split(urlRegex).map((part, index) => {
        if (urlRegex.test(part)) {
          return (
            <Link
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              $isMyMessage={isMyMessage}
            >
              <TextWithHighlight text={part} keywords={[searchText]} />
            </Link>
          );
        }
        return (
          <TextWithHighlight key={index} text={part} keywords={[searchText]} />
        );
      })}
    </MessageContainer>
  );
};

const MessageContainer = styled.div`
  width: 100%;
`;

const Link = styled.a<{ $isMyMessage: boolean }>`
  color: ${({ $isMyMessage }) =>
    $isMyMessage ? colorsPallet.emptyShade : colorsPallet.primary};
  text-decoration: underline;
`;
