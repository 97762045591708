import * as client from "./client";
import { CampaignRecipientSchemaType } from "@shared/validator/features/campaignRecipient";

export const getCampaignRecipientList = () => {
  return client.get<{}, CampaignRecipientSchemaType[]>(
    "/campaignRecipient/getCampaignRecipientList",
    {}
  );
};

export const exportCampaignRecipientCSV = () => {
  return client.getBlob<{}, Blob>(
    "/campaignRecipient/exportCampaignRecipientCSV",
    {}
  );
};
