import React, { useState } from "react";
import { useToast } from "src/components/Toast";
import { ModalPortal, ModalBody } from "src/components/Modal";
import { ModalWrapper, ModalHeader } from "src/pages/pta/admin/ProfilePage";
import * as Margin from "src/components/Margin";
import { Button } from "src/components/Button";
import styled from "styled-components";
import Cookies from "js-cookie";
import { generateCodeChallenge } from "../../utils";
import { generateRandomString } from "src/@shared/utils/generateRandomString";
import { useCurrentCommunityId } from "../../../../../hooks/router";

interface Props {
  isOpen: boolean;
  close: () => void;
  mode: "register" | "add_friend";
}

export const LinkLineModal: React.FC<Props> = ({ isOpen, close, mode }) => {
  const toast = useToast();
  const { communityId } = useCurrentCommunityId();

  const [isProcessing, setIsProcessing] = useState(false);

  const handleClickWithLineLogin = async () => {
    if (isProcessing) return;

    try {
      const lineChannelId = import.meta.env.VITE_LINE_LOGIN_CHANNEL_ID;
      const redirectUri = `${window.location.origin}/line/callback?communityId=${communityId}`;
      const state = generateRandomString();
      const nonce = generateRandomString();
      const codeVerifier = generateRandomString(128);
      const codeChallenge = await generateCodeChallenge(codeVerifier);

      Cookies.set("lineLoginState", state, { expires: 1 / 144 }); // 10分
      Cookies.set("lineCodeVerifier", codeVerifier, { expires: 1 / 144 }); // 10分

      const scope = "openid%20profile";
      const botPrompt = "aggressive";
      const prompt = "consent";

      const lineAuthUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${lineChannelId}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&state=${state}&scope=${scope}&nonce=${nonce}&bot_prompt=${botPrompt}&prompt=${prompt}&code_challenge=${codeChallenge}&code_challenge_method=S256`;

      const debugStoredState = Cookies.get("lineLoginState") ?? "";
      const debugCodeVerifier = Cookies.get("lineCodeVerifier") ?? "";

      if (!debugStoredState || !debugCodeVerifier) {
        console.error("Cookieの設定に失敗しました");
        return;
      }

      window.location.href = lineAuthUrl;
      close();
    } catch (err) {
      toast.error("LINEの連携に失敗しました");
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      {isOpen && (
        <ModalPortal onClose={() => close()}>
          <ModalWrapper>
            <ModalHeader>
              {mode === "register"
                ? "LINEアカウントの連携"
                : "polyfitのLINEアカウントと友達になる"}{" "}
            </ModalHeader>
            <ModalBody>
              <Margin.Margin marginTop={20} />
              <ModalContent>
                {mode === "register" ? (
                  <>
                    polyfitとLINEを連携してもっと便利に使いましょう！
                    <br />
                    <OrderedList>
                      <li>
                        下の「LINEアカウントと連携」ボタンをタップして、LINEログインをしてください。
                      </li>
                      <li>
                        polyfitのLINEアカウントと友だちになればLINEでpolyfitからのお知らせを受け取れます。
                      </li>
                    </OrderedList>
                  </>
                ) : (
                  <>
                    polyfitからのLINE通知が届かない場合はpolyfitのLINEアカウントと友達になっていない可能性があります。
                    <br />
                    <OrderedList>
                      <li>
                        下の「LINEアカウントと連携」ボタンをタップして、polyfitのLINEアカウントと友達になってください。
                      </li>
                    </OrderedList>
                  </>
                )}
              </ModalContent>
              <Margin.Margin marginTop={40} />
              <Button
                onClick={handleClickWithLineLogin}
                color={"subPrimary"}
                fill={true}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                LINEアカウントと連携
              </Button>
            </ModalBody>
          </ModalWrapper>
        </ModalPortal>
      )}
    </>
  );
};

const ModalContent = styled.div`
  font-size: 14px;
  line-height: 1.5;
`;

const OrderedList = styled.ol`
  padding-left: 20px;
  margin: 10px 0;

  li {
    margin-bottom: 10px;
  }
`;
